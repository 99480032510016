<template>
  <div class="main-view-container d-flex align-items-center flex-column">
    <slot/>
    <BaseFooter class="d-none d-md-flex"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as modes from '@/constants/modes'
import { fullScreenRoutes } from '@/constants/liveStream'
import BaseFooter from '@/components/base/BaseFooter.vue'

export default {
  name: 'MainView',
  components: { BaseFooter },
  modes,
  inject: ['media'],
  props: {
    transition: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters([
      'mode'
    ])
  },
  watch: {
    $route: 'updatePadding'
  },
  async mounted() {
    this.updatePadding()
  },
  methods: {
    updatePadding() {
      this.$nextTick(() => {
        this.$el.classList.toggle('pt-0', fullScreenRoutes.indexOf(this.$route.name) >= 0)
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/scss/bootstrap/custom";

.main-view {
  &-container {
    position: relative;
  }

  &-switch {
    position: absolute !important;
    z-index: $zindex-sticky;
    top: map-get($spacers, 4);
    left: 50%;
    transform: translateX(-50%);

    .btn {
      width: 9rem;
    }
  }

  &-content {
    max-width: 58rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }
}

.text-width {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.live-ticker-container {
  width: 32rem;
  max-width: calc(100vw - #{$grid-gutter-width});
}
</style>
