import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'RunnerDisqualified',
  functional: true,
  render: function (h, ctx) {
    return h(
      "svg",
      _mergeJSXProps([{
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
      }, ctx.data]),
      [h("path", {
        attrs: { d: "M12.25 14.22c-.06.08-.17.11-.26.16-.09.04-.12-.16.02-.24.31-.19.46-.95.28-.95-.06 0-.17.57-.36.49-.06-.02-.03-.08-.03-.12.13-.68-.04-.93.22-1.38.14-.24 0-.5-.16-1.06-.72-2.42-.14-1.4-.74-4.16-.06.11-.42 1.24-.3 1.91.04.23.12.45.11.68 0 .92.43 1.93.43 2.38 0 4.1-.52 3.86-.55 4.92-.06 1.45-.08 1.13.04 1.55.36 1.18-.29 3.01-.45 4.17-.06.43.33.81.68 1.06.13.08.26.13.22.23-.04.15-.09.05-1.43.14-.46.05-.3-.71-.33-.81a.61.61 0 0 1 .02-.46c.18-.38.02-1.33-.11-2.34-.17-1.28.2-1.4.01-1.96-.1-.29-.13-.59-.13-.9 0-.99-.37-4.04-.4-4.11 0 0-.02-.11-.07-.11s-.08.1-.08.1-.36 2.87-.36 3.32c-.06 2.31-.35 1.58-.19 2.33.24 1.21-.38 2.99-.08 3.68.12.26.01.4.01.56.03.17.01.34-.03.51.05.33-.63.13-1.51.13-.15 0-.24-.15-.08-.23.26-.13.45-.34.64-.55.43-.48.06-.76-.33-3.48-.2-1.37.49-1.32.02-3.44-.09-.39-.19-.78-.26-1.17-.18-1.03-.2-3.12-.18-3.22.03-.6 1.12-3.4.22-4.86-.46 3.13-.2 2.61-.88 4.65-.16.65.15.48.15 1.14 0 .42.07.8.07.81 0 .03.02.08-.03.09a.1.1 0 0 1-.11-.04c-.21-.24-.15-.37-.21-.47-.01-.02-.01-.06-.05-.05-.02 0-.05.04-.05.06-.02.23-.1.22.18.81.05.13.22.11.24.3.05.36-.99-.55-.88-1.29.06-.35.02-.71 0-1.07-.03-.25.08-.53.03-1.44-.04-.61-.02-1.19.28-1.94.21-.4-.03-1.3.19-2.02.07-.21-.12-1.1.12-1.67.3-.73.66-.73.76-.8.22-.07.09.05.87-.34.65-.31.99-.42.81-1.05-.17-.42-.12-.42-.14-.42-.16.04-.27-.61-.2-.68.01-.02.03-.03.05-.02.1.05-.15-.59.02-.89.07-.11.16-.14.18-.22.05-.19.17-.26.35-.32.13-.19 1.05-.18 1.36.23.1.31.32-.09.16 1.17 0 .01 0 .03.02.02.11-.04.05.34.01.45 0 .11-.04.28-.15.26-.03 0 .02-.02-.08.25-.14.83-.17.75.79 1.23.25.12.51.27.7.3.07.01.13.02.18.04.84.26.93 1.78.82 2.06-.04.13-.05.25 0 .38.25.69-.07 1.36.22 2.19.46 1.25.09 1.89.24 2.93.05.3-.06.73.01 1.41.08.41-.3.87-.53 1.18z" }
      }), h(
        "g",
        {
          attrs: { fill: "none", stroke: "currentColor", "stroke-miterlimit": "10" }
        },
        [h("circle", {
          attrs: { cx: "19.5", cy: "4.5", r: "4" }
        }), h("path", {
          attrs: { d: "M16.67 7.33l5.66-5.66" }
        })]
      )]
    );
  }
};