<template>
  <div>
    <slot
      v-if="$scopedSlots.before || header"
      name="before"
    >
      <div class="text-muted font-weight-bold text-condensed small text-uppercase mb-3">
        <template>{{ header }}</template>
      </div>
    </slot>
    <section class="d-flex align-items-center justify-content-center">
      <button
        class="fab fab-sm fab-whatsapp"
        aria-label="Share on WhatsApp"
        @click="share(whatsAppLink)"
      >
        <icon src="@/assets/icons/social/whatsapp.svg"/>
      </button>
      <button
        class="fab fab-sm fab-facebook"
        aria-label="Share on Facebook"
        @click="share(facebookLink)"
      >
        <icon
          src="@/assets/icons/social/facebook.svg"
          class="w-100 h-100"
        />
      </button>
      <button
        class="fab fab-sm fab-twitter"
        aria-label="Share on Twitter"
        @click="share(twitterLink)"
      >
        <icon
          src="@/assets/icons/social/twitter.svg"
        />
      </button>
      <button
        class="fab fab-sm fab-linkedin"
        aria-label="Share on LinkedIn"
        @click="share(linkedinLink)"
      >
        <icon
          src="@/assets/icons/social/linkedin.svg"
        />
      </button>
      <button
        type="button"
        class="fab fab-sm fab-primary text-white"
        aria-label="Copy link"
        @click="copyLink"
      >
        <transition name="fade">
          <icon
            v-if="copied"
            key="copied"
            src="@/assets/icons/check.svg"
          />
          <icon
            v-else
            src="@/assets/icons/link.svg"
          />
        </transition>
      </button>
    </section>
    <slot name="downloadlink"/>
  </div>
</template>

<script>
import copy from 'clipboard-copy'

export default {
  name: 'SocialSharing',
  props: {
    header: {
      type: String,
      default: undefined
    },
    url: {
      type: String,
      default: undefined
    },
    /**
     * @type {VueI18n.TranslateResult | string}
     */
    text: {
      type: String,
      default: undefined
    },
    image: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      copied: false
    }
  },
  computed: {
    $text() {
      return encodeURIComponent(this.text || document.title)
    },
    $rawUrl() {
      return this.url || window.location.href || ''
    },
    $url() {
      return encodeURIComponent(this.$rawUrl)
    },
    whatsAppLink() {
      return {
        url: this.$url,
        fullUrl: `https://api.whatsapp.com/send?text=${this.$url}`
      }
    },
    twitterLink() {
      return {
        url: this.$url,
        text: this.$text,
        fullUrl: `https://twitter.com/intent/tweet/?text=${this.$text}&url=${this.$url}`
      }
    },
    facebookLink() {
      return {
        url: this.$url,
        fullUrl: `https://facebook.com/sharer/sharer.php?u=${this.$url}`
      }
    },
    linkedinLink() {
      return {
        url: this.$url,
        fullUrl: `https://www.linkedin.com/shareArticle?url=${this.$url}`
      }
    }
  },
  methods: {
    /**
     * @param link {{
     *   url: string
     *   text?: string
     *   fullUrl: string
     * }}
     */
    async share({
      fullUrl
    }) {
      const data = {
        title: this.text || document.title,
        url: this.$rawUrl
      }

      if (navigator.share && navigator.canShare(data)) {
        try {
          await navigator.share(data)
        } catch {
          window.open(this.$rawUrl, '_blank')
        }
      } else {
        window.open(fullUrl, '_blank')
      }
    },
    copyLink() {
      copy(this.$rawUrl)
        .then(() => {
          this.copied = true
          setTimeout(() => {
            this.copied = false
          }, 1000)
        })
    }
  }
}
</script>
