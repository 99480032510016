export default function mediaQueriesMixinFactory(breakpoints) {
  const keys = Object.keys(breakpoints)
  return {
    provide() {
      return {
        media: this.media
      }
    },
    data() {
      const media = Object.create(null)

      keys.forEach((breakpoint) => {
        media[breakpoint] = false
      })

      return {
        media
      }
    },
    mounted() {
      keys.forEach((breakpoint) => {
        const mql = window.matchMedia(`(min-width: ${breakpoints[breakpoint]})`)
        this.media[breakpoint] = mql.matches

        if (typeof mql.addListener !== 'undefined') {
          mql.addListener((e) => {
            this.media[breakpoint] = e.matches
          })
        }
      })
    }
  }
}
