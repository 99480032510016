import * as positions from '@/constants/liveStream'
import { SET_POSITION } from '../mutation-types'

export default {
  state() {
    return {
      position: positions.MINIMIZED
    }
  },
  mutations: {
    [SET_POSITION](state, position) {
      if (position in positions) {
        state.position = position
      }
    }
  },
  actions: {
    minimizePlayer({ commit }) {
      commit(SET_POSITION, positions.MINIMIZED)
    },
    maximizePlayer({ commit }) {
      commit(SET_POSITION, positions.MAXIMIZED)
    },
    dockPlayer({ commit }) {
      commit(SET_POSITION, positions.DOCKED)
    }
  },
  getters: {
    liveStreamPosition: state => state.position
  }
}
