<template>
  <div v-observe-visibility="visibilityChanged">
    <div class="card mx-auto mw-[1440px]">
      <div class="card-body px-3 px-md-4 pt-2 pb-3 py-md-5">
        <div
          key="stream"
        >
          <div
            v-if="hasMultipleStreams"
            class="d-none d-md-flex justify-center align-items-center mb-3"
          >
            <h1
              v-t="{ path: 'stream.primary', args: [$eventYear] }"
              class="text-body w-100 text-center"
            />
          </div>

          <div class="aspect-16by9">
            <div
              :class="[
                $style.stream,
                'aspect-16by9',
                $style[position.toLowerCase()],
                slideIn && !closedMiniPlayer && !shouldHideMiniPlayer && $style.slideIn
              ]"
            >
              <div
                v-show="slideIn"
                style="display:grid;place-content:end"
              >
                <button
                  class="btn p-0 mb-1"
                  @click="closedMiniPlayer = true"
                >
                  <icon
                    name="close"
                    class="icon-md text-secondary"
                  />
                </button>
              </div>
              <RedBullVideo
                v-if="redBullVideoId"
                :video-id="redBullVideoId"
                details="hidden"
              />
              <RedBullVideo
                v-else-if="streamId"
                :video-id="streamId"
                muted="NOT_MUTED"
                phasing="true"
                details="hidden"
              />
              <div
                v-else
                class="embed-responsive embed-responsive-16by9"
              >
                <iframe
                  v-if="url"
                  :src="url"
                  class="embed-responsive-item"
                  width="560"
                  height="315"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowfullscreen
                />
                <router-link
                  :to="{name: 'global'}"
                  class="live-stream-overlay d-lg-none"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ObserveVisibility } from 'vue-observe-visibility'
import RedBullVideo from '@/components/RedBullVideo.vue'
import { LIVE, POST, PRE } from '@/constants/modes'
import { fullScreenRoutes, positions } from '@/constants/liveStream'

export default {
  name: 'LiveStreams',
  components: {
    RedBullVideo
  },
  directives: {
    ObserveVisibility
  },
  inject: ['media'],
  data() {
    return {
      slideIn: false,
      isVisible: true,
      closedMiniPlayer: false
    }
  },
  computed: {
    ...mapGetters({
      position: 'liveStreamPosition',
      mode: 'mode',
      startTime: 'startTime'
    }),
    liveStreams() {
      return process.env.VUE_APP_STREAM_PRIMARY_URL
    },
    hasMultipleStreams() {
      return [PRE, LIVE].includes(this.mode)
    },
    url() {
      if (this.mode === POST) {
        return process.env.VUE_APP_POST_STREAM_URL.length > 5 ? process.env.VUE_APP_POST_STREAM_URL : undefined
      }

      return this.liveStreams
    },
    streamId() {
      if (!this.url) {
        return undefined
      }

      // probably already an ID
      if (!/^http/.test(this.url)) {
        return this.url
      }

      // try to extract id from url
      const [id] = this.url.match(/rrn(:[^&?]+)+/)
      return id || undefined
    },
    redBullVideoId() {
      // show explainer Video until 30min before start
      if ((new Date()).getTime() < new Date(this.startTime.getTime() - 30 * 60 * 1000)) {
        return process.env.VUE_APP_PRE_VIDEO_ID.length > 5 ? process.env.VUE_APP_PRE_VIDEO_ID : undefined
      }

      const videoId = process.env.VUE_APP_POST_VIDEO_ID
      if (this.mode === POST && videoId) {
        return videoId
      }
      return undefined
    },
    shouldHideMiniPlayer() {
      return ['imageUpload'].includes(this.$route.name) ?? false
    }
  },
  watch: {
    position(position) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.slideIn = position === positions.MINIMIZED
        }, 100)
      })
    },
    mode: 'positionPlayer',
    $route: 'positionPlayer',
    isVisible: 'positionPlayer',
    'media.lg': 'positionPlayer'
  },
  mounted() {
    if (fullScreenRoutes.indexOf(this.$route.name) >= 0) {
      this.minimizePlayer()
    }
  },
  methods: {
    ...mapActions([
      'dockPlayer',
      'minimizePlayer',
      'maximizePlayer'
    ]),
    visibilityChanged(isVisible) {
      this.isVisible = isVisible
    },
    positionPlayer() {
      if (!this.isVisible) {
        this.minimizePlayer()
      } else {
        this.maximizePlayer()
      }
    }
  }
}
</script>

<style lang="scss" module>
.stream {
  aspect-ratio: 16 / 9;

  &.minimized {
    position: fixed;
    z-index: $zindex-sticky;
    transform: translate3d(150%, 0, 0);

    margin-bottom: env(safe-area-inset-bottom);
    margin-right: env(safe-area-inset-right);

    width: 80%;
    bottom: 2rem;
    right: 0.75rem;

    @include media-breakpoint-up(md) {
      width: 14rem;
      bottom: 2rem;
      right: 2rem;
    }

    @media (min-height: 650px) and (min-width: #{breakpoint-min(md, $grid-breakpoints)}) {
      width: 18rem;
      bottom: 2rem;
      right: 2rem;
    }
  }

  &.slideIn {
    transform: translate3d(0, 0, 0);
    transition: transform .6s .3s ease-in-out;
  }
}
</style>
