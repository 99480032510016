import Vue from 'vue'
import array from '@/util/array'
import { SET_LOCATION, PATCH_LOCATION } from '../mutation-types'

export default {
  state() {
    return {
      items: {},
      slugs: {}
    }
  },
  mutations: {
    [SET_LOCATION](state, location) {
      Vue.set(state.items, location.id, location)
      Vue.set(state.slugs, location.webcode, location)
    },
    [PATCH_LOCATION](state, locations) {
      array(locations).forEach((location) => {
        Vue.set(state.items, location.id, location)
        Vue.set(state.slugs, location.webcode, location)
        // Vue.set(state, location.id, Object.assign({}, state.locations[location.id], location))
      })
    }
  },
  actions: {},
  getters: {
    locationById: ({ slugs = {}, items = {} }) => id => slugs[id] || items[id],
    locations: state => Object.values(state.items || {})
  }
}
