<template>
  <span :is="tag">{{ $prefix }}<slot :value="tweeningValue">{{ tweeningValue }}</slot>{{ $suffix }}</span>
</template>

<script>
import tweensValue from '@/mixins/tweensValue'

const MARKER = '{value}'

export default {
  name: 'AnimatedNumber',
  mixins: [tweensValue],
  props: {
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    template: {
      type: String,
      default: undefined
    },
    tag: {
      type: String,
      default: 'span'
    },
    decimals: {
      type: Number,
      default: 2
    }
  },
  computed: {
    $prefix() {
      if (this.template) {
        return this.template.split(MARKER)[0]
      }
      return this.prefix
    },
    $suffix() {
      if (this.template) {
        return this.template.split(MARKER)[1]
      }
      return this.suffix
    }
  }
}
</script>

<style lang="scss">

</style>
