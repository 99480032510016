const ioc = {
  AD: 'AND',
  AE: 'UAE',
  AF: 'AFG',
  AG: 'ANT',
  AI: 'AIA',
  AL: 'ALB',
  AM: 'ARM',
  AN: 'AHO',
  AO: 'ANG',
  AQ: 'ATA',
  AR: 'ARG',
  AS: 'ASA',
  AT: 'AUT',
  AU: 'AUS',
  AW: 'ARU',
  AX: 'ALA',
  AZ: 'AZE',
  BA: 'BIH',
  BB: 'BAR',
  BD: 'BAN',
  BE: 'BEL',
  BF: 'BUR',
  BG: 'BUL',
  BH: 'BRN',
  BI: 'BDI',
  BJ: 'BEN',
  BL: 'BLM',
  BM: 'BER',
  BN: 'BRU',
  BO: 'BOL',
  BQ: 'BES',
  BR: 'BRA',
  BS: 'BAH',
  BT: 'BHU',
  BV: 'BVT',
  BW: 'BOT',
  BY: 'BLR',
  BZ: 'BIZ',
  CA: 'CAN',
  CC: 'CCK',
  CD: 'COD',
  CF: 'CAF',
  CG: 'CGO',
  CH: 'SUI',
  CI: 'CIV',
  CK: 'COK',
  CL: 'CHI',
  CM: 'CMR',
  CN: 'CHN',
  CO: 'COL',
  CR: 'CRC',
  CS: 'SCG',
  CU: 'CUB',
  CV: 'CPV',
  CW: 'CUW',
  CX: 'CXR',
  CY: 'CYP',
  CZ: 'CZE',
  DE: 'GER',
  DJ: 'DJI',
  DK: 'DEN',
  DM: 'DMA',
  DO: 'DOM',
  DZ: 'ALG',
  EC: 'ECU',
  EE: 'EST',
  EG: 'EGY',
  EH: 'ESH',
  ER: 'ERI',
  ES: 'ESP',
  ET: 'ETH',
  FI: 'FIN',
  FJ: 'FIJ',
  FK: 'FLK',
  FM: 'FSM',
  FO: 'FRO',
  FR: 'FRA',
  GA: 'GAB',
  GB: 'GBR',
  GD: 'GRN',
  GE: 'GEO',
  GF: 'GUF',
  GG: 'GGY',
  GH: 'GHA',
  GI: 'GIB',
  GL: 'GRL',
  GM: 'GAM',
  GN: 'GUI',
  GP: 'GLP',
  GQ: 'GEQ',
  GR: 'GRE',
  GS: 'SGS',
  GT: 'GUA',
  GU: 'GUM',
  GW: 'GBS',
  GY: 'GUY',
  HK: 'HKG',
  HM: 'HMD',
  HN: 'HON',
  HR: 'CRO',
  HT: 'HAI',
  HU: 'HUN',
  ID: 'INA',
  IE: 'IRL',
  IL: 'ISR',
  IM: 'IMN',
  IN: 'IND',
  IO: 'IOT',
  IQ: 'IRQ',
  IR: 'IRI',
  IS: 'ISL',
  IT: 'ITA',
  JE: 'JEY',
  JM: 'JAM',
  JO: 'JOR',
  JP: 'JPN',
  KE: 'KEN',
  KG: 'KGZ',
  KH: 'CAM',
  KI: 'KIR',
  KM: 'COM',
  KN: 'SKN',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KUW',
  KY: 'CAY',
  KZ: 'KAZ',
  LA: 'LAO',
  LB: 'LBN',
  LC: 'LCA',
  LI: 'LIE',
  LK: 'SRI',
  LR: 'LBR',
  LS: 'LES',
  LT: 'LTU',
  LU: 'LUX',
  LV: 'LAT',
  LY: 'LBA',
  MA: 'MAR',
  MC: 'MON',
  MD: 'MDA',
  ME: 'MNE',
  MF: 'MAF',
  MG: 'MAD',
  MH: 'MHL',
  MK: 'MKD',
  ML: 'MLI',
  MM: 'MYA',
  MN: 'MGL',
  MO: 'MAC',
  MP: 'MNP',
  MQ: 'MTQ',
  MR: 'MTN',
  MS: 'MSR',
  MT: 'MLT',
  MU: 'MRI',
  MV: 'MDV',
  MW: 'MAW',
  MX: 'MEX',
  MY: 'MAS',
  MZ: 'MOZ',
  NA: 'NAM',
  NC: 'NCL',
  NE: 'NIG',
  NF: 'NFK',
  NG: 'NGR',
  NI: 'NCA',
  NL: 'NED',
  NO: 'NOR',
  NP: 'NEP',
  NR: 'NRU',
  NU: 'NIU',
  NZ: 'NZL',
  OM: 'OMA',
  PA: 'PAN',
  PE: 'PER',
  PF: 'PYF',
  PG: 'PNG',
  PH: 'PHI',
  PK: 'PAK',
  PL: 'POL',
  PM: 'SPM',
  PN: 'PCN',
  PR: 'PUR',
  PS: 'PLE',
  PT: 'POR',
  PW: 'PLW',
  PY: 'PAR',
  QA: 'QAT',
  RE: 'REU',
  RO: 'ROU',
  RS: 'SRB',
  RU: 'RUS',
  RW: 'RWA',
  SA: 'KSA',
  SB: 'SOL',
  SC: 'SEY',
  SD: 'SUD',
  SE: 'SWE',
  SG: 'SGP',
  SH: 'SHN',
  SI: 'SLO',
  SJ: 'SJM',
  SK: 'SVK',
  SL: 'SLE',
  SM: 'SMR',
  SN: 'SEN',
  SO: 'SOM',
  SR: 'SUR',
  SS: 'SSD',
  ST: 'STP',
  SV: 'ESA',
  SX: 'SXM',
  SY: 'SYR',
  SZ: 'SWZ',
  TC: 'TCA',
  TD: 'CHA',
  TF: 'ATF',
  TG: 'TOG',
  TH: 'THA',
  TJ: 'TJK',
  TK: 'TKL',
  TL: 'TLS',
  TM: 'TKM',
  TN: 'TUN',
  TO: 'TGA',
  TR: 'TUR',
  TT: 'TTO',
  TV: 'TUV',
  TW: 'TWN',
  TZ: 'TAN',
  UA: 'UKR',
  UG: 'UGA',
  UM: 'UMI',
  US: 'USA',
  UY: 'URU',
  UZ: 'UZB',
  VA: 'VAT',
  VC: 'VIN',
  VE: 'VEN',
  VG: 'IVB',
  VI: 'ISV',
  VN: 'VIE',
  VU: 'VAN',
  WF: 'WLF',
  WS: 'SAM',
  XK: 'KOS',
  YE: 'YEM',
  YT: 'MYT',
  ZA: 'RSA',
  ZM: 'ZAM',
  ZW: 'ZIM'
}

export default function getIoc(iso) {
  return ioc[iso] ?? iso
}
