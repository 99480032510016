export default {
  props: {
    theme: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    themedClass(className) {
      return `${className}-${this.theme}`
    }
  }
}
