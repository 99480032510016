<script>
/* eslint-disable no-param-reassign */
export default {
  name: 'AnimateBetweenElements',
  props: {
    interval: {
      type: Number,
      default: 2400
    },
    duration: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      index: 0
    }
  },
  mounted() {
    if (this.$slots.default) {
      const timer = setInterval(() => {
        this.index = (this.index + 1) % this.$slots.default.length
      }, this.interval)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
    }
  },
  methods: {
    beforeLeave(el) {
      const {
        marginLeft, marginTop, width, height
      } = window.getComputedStyle(el)
      el.style.position = 'absolute'
      el.style.animationDuration = `${this.duration}ms`
      el.style.left = `${el.offsetLeft - parseFloat(marginLeft, 10)}px`
      el.style.top = `${el.offsetTop - parseFloat(marginTop, 10)}px`
      el.style.width = width
      el.style.height = height
    }
  },
  render(h) {
    if (!this.$slots.default) {
      return undefined
    }

    return h('transition', {
               props: {
                 tag: 'div',
                 name: 'fade',
                 enterActiveClass: this.$style.transition,
                 leaveActiveClass: this.$style.transition,
                 enterFromClass: this.$style['opacity-0'],
                 leaveToClass: this.$style['opacity-0']
               },
               on: {
                 beforeLeave: this.beforeLeave
               }
             },
             [
               h('div', { key: `item${this.index}` }, [
                 this.$slots.default[this.index] || undefined
               ])
             ])
  }
}
</script>

<style lang="scss" module>
.transition {
  transition: opacity .2s ease-out;
}

.opacity-0 {
  opacity: 0;
}

</style>
