<script>
const sizes = ['xs', 'sm', 'md', 'lg', 'xl']

export default {
  name: 'Icon',
  functional: true,
  props: {
    name: {
      type: [String, Object],
      default: undefined
    },
    // eslint-disable-next-line vue/require-prop-types
    src: {
      // can't add type check for Module and Object doesn't work
      default: undefined
    }
  },
  render(h, ctx) {
    const {
      data: {
        staticClass,
        class: dynamicClass,
        attrs = {},
        ...data
      } = {},
      props: { name, src }
    } = ctx
    const cls = ['icon', staticClass, dynamicClass]
    const size = sizes.find(s => s in attrs)
    if (size) {
      cls.push(`icon-${size}`)
    }

    let component
    if (src) {
      component = 'default' in src ? src.default : src
    } else {
      component = typeof name === 'string' ? `icon-${name}` : name
    }

    if (!component) {
      throw new Error('[Icon] No `src` or `name` provided')
    }
    const vnode = h(component, {
      class: cls,
      attrs: {
        role: attrs.role || 'img',
        ...attrs
      },
      ...data
    })

    if (vnode.data.attrs) {
      // remove xmlns which is not needed when inlined
      delete vnode.data.attrs.xmlns

      // remove size attrs
      sizes.forEach((item) => {
        delete vnode.data.attrs[item]
      })
    }

    return vnode
  }
}
</script>
