import { wrap, proxy } from 'comlink'
import { UPDATE_INTERVAL } from '@/constants/intervals'
import * as types from '@/store/mutation-types'
import transformUpdates from '@/worker/transformer/transformUpdates'
import { POST } from '@/constants/modes'
import fetchConfig from '@/util/fetchConfig'

export default {
  worker: null,
  methods: {
    async getWorker() {
      if (this.$options.worker) {
        return this.$options.worker
      }

      const Updates = wrap(new Worker(new URL('@/worker/updates.worker', import.meta.url)))
      const updates = await new Updates()

      const { commit } = this.$store
      updates.on(proxy(commit))
      this.$options.worker = updates
      return updates
    },
    async processUpdates() {
      try {
        const result = await fetchConfig()
        const {
          refresh,
          startDate,
          locations,
          participants,
          leaders,
          globals,
          carSpeed,
          carDistance,
          features,
          raceFinished
        } = transformUpdates(result)
        if (startDate) {
          this.$emit(types.SET_EVENT_DATE, startDate)
        }
        this.$store.commit(types.SET_REFRESH, refresh)
        this.$store.commit(types.SET_CATCHER_CAR_SPEED, carSpeed)
        this.$store.commit(types.SET_CATCHER_CAR_DISTANCE, carDistance)
        this.$store.commit(types.PATCH_LOCATION, locations)
        this.$store.commit(types.PATCH_PARTICIPANT, participants)
        this.$store.commit(types.SET_LEADERS, leaders)
        this.$store.commit(types.SET_IN, globals.in)
        this.$store.commit(types.SET_OUT, globals.out)
        this.$store.commit(types.SET_FEATURES, features)
        this.$store.commit(types.SET_RACE_FINISHED, raceFinished || false)
      } catch (e) {
        // eslint-disable-next-line
        console.error(e)
      }
    }
  },
  mounted() {
    if (this.$store.getters.mode === POST) {
      this.processUpdates()
    } else {
      this.getWorker().then(worker => worker.start(UPDATE_INTERVAL))
    }
  },
  beforeDestroy() {
    const { worker } = this.$options
    if (worker) {
      worker.stop()
      try {
        worker.terminate()
      } catch (e) {
        // eslint-disable-next-line
        console.error('pollsUpdates:terminateWorker', e)
      }
    }
  }
}
