import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import dot from 'dot-object'

import { SET_EVENT_DATE } from '@/store/mutation-types'
import app from './modules/app'
import event from './modules/event'
import radio from './modules/radio'
import locations from './modules/locations'
import participants from './modules/participants'
import teams from './modules/teams'
import pinboard from './modules/pinboard'
import points from './modules/points'
import liveStream from './modules/liveStream'
import donateModal from './modules/donateModal'
import blog from './modules/blog'
import tracking from './plugins/tracking'

Vue.use(Vuex)

const storagePaths = [
  'app.units',
  'app.locale',
  'route',
  'donateModal',
  'pinboard'
]

export default new Vuex.Store({
  modules: {
    app,
    event,
    radio,
    locations,
    participants,
    teams,
    pinboard,
    points,
    liveStream,
    donateModal,
    blog
  },
  plugins: [
    tracking,
    createPersistedState({
      key: 'WfLWR-LE',
      paths: storagePaths,
      reducer: (state, paths) => {
        const pinnedItems = state.pinboard.map(item => `${item.replace(':', 's.')}`)
        return paths.length === 0
          ? state
          : paths.concat(pinnedItems).reduce((substate, path) => dot.str(path, dot.pick(path, state), substate), {})
      },
      getState(key, storage) {
        try {
          const value = storage.getItem(key)
          if (value && typeof value !== 'undefined') {
            const rawValue = JSON.parse(value)
            const whitelistedValue = {}

            storagePaths.forEach((path) => {
              dot.str(path, dot.pick(path, rawValue), whitelistedValue)
            })

            return whitelistedValue
          }

          return undefined
        } catch (err) {
          // handle storage errors
        }

        return undefined
      },
      filter({ type }) {
        return type !== SET_EVENT_DATE
      }
    })
  ]
  // strict: process.env.NODE_ENV !== 'production'
})
