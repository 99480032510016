import { SET_MODAL_OPEN, SET_MODAL_URL } from '@/store/mutation-types'
import i18n from '@/i18n'

export default {
  state() {
    return {
      open: false,
      url: undefined
    }
  },
  mutations: {
    [SET_MODAL_OPEN](state, payload) {
      state.open = payload
    },
    [SET_MODAL_URL](state, payload) {
      state.url = payload
    }
  },
  actions: {
    donate({ commit }, { participantId, teamId, distance } = {}) {
      const suffix = 'donate'
      let url = `/{locale}/overlays/${suffix}`
      const params = new URLSearchParams()
      if (distance) {
        url += '/perkm'
      }
      if (participantId) {
        params.append(distance ? 'participantId' : 'receiver', participantId)
      }
      if (teamId) {
        params.append('team', teamId)
      }
      params.append('source', 'lx')

      if ([...params].length) {
        url = `${url}?${params.toString()}`
      }

      commit(SET_MODAL_URL, url)
      commit(SET_MODAL_OPEN, true)
    },
    hideDonate({ commit }) {
      commit(SET_MODAL_OPEN, false)
    }
  },
  getters: {
    donateUrl: state => state.url?.replace('{locale}', i18n.locale),
    showDonateModal: state => state.open
  }
}
