<template>
  <aside class="side-nav-bar flex-lg-column align-items-center pb-lg-3 pt-lg-3">
    <nav class="nav flex-lg-column flex-nowrap w-100">
      <component
        :is="is"
        v-for="{ is, name, nameChoice, url, target, icon } in $menu"
        :key="JSON.stringify(url || name)"
        v-bind="target ? { href: url } : { to: url }"
        :target="target"
        class="nav-link"
        @click.native="trackClick(nameChoice >= 0 ? $tc(`menu.${name}`, nameChoice) : $t(`menu.${name}`))"
        @click="trackClick(nameChoice >= 0 ? $tc(`menu.${name}`, nameChoice) : $t(`menu.${name}`))"
      >
        <div class="nav-link-icon">
          <icon
            v-if="icon"
            :name="icon"
            :class="mode.toLowerCase()"
            class="mb-1 mb-lg-2"
          />
          <span
            v-if="name === 'pinboard' && pinnedCount > 0"
            :class="`badge-${mobile ? 'secondary' : 'light'}`"
            class="badge badge-pill"
          >
            {{ pinnedCount }}
          </span>
        </div>
        <span class="text-truncate w-100">
          <template v-if="nameChoice >= 0">{{ $tc(`menu.${name}`, nameChoice) }}</template>
          <template v-else>{{ $t(`menu.${name}`) }}</template>
        </span>
      </component>

    </nav>
    <slot name="after"/>
  </aside>
</template>

<script>
import DropDown from '@/components/DropDown.vue'
import { mapGetters } from 'vuex'
import menuItems from '@/mixins/menuItems'

const MODEL_EVENT = 'change'

export default {
  name: 'SideNavBar',
  components: {
    DropDown
  },
  mixins: [menuItems],
  model: {
    event: MODEL_EVENT
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'pinnedCount'
    ]),
    $menu() {
      if (this.mobile) {
        return this.menu
      }

      return this.menu.filter(({ name }) => name !== 'blog')
    },
    showMore: {
      get() {
        return this.value
      },
      set(value) {
        if (this.mobile) {
          this.$emit(MODEL_EVENT, value)
        }
      }
    },
    showMoreDesktop: {
      get() {
        return this.value
      },
      set(value) {
        if (!this.mobile) {
          this.$emit(MODEL_EVENT, value)
        }
      }
    }
  },
  methods: {
    trackClick(text) {
      this.$gtmEvent({
        event: 'gaEvent',
        eventCategory: 'Navigation',
        eventAction: 'Sidebar Navigation',
        eventLabel: text
      })
      this.$gtmEvent({
        event: 'ga4Event',
        event_name: 'navigation',
        navigation: {
          element_position: 'Sidebar Navigation',
          content_name: text
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/bootstrap/custom";

.side-nav-bar {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  z-index: $zindex-fixed;
  background: $white;
  border-top: 1px solid gray('300');

  @media (min-width: #{breakpoint-min($split-view-breakpoint, $grid-breakpoints)}) and (max-height: 747px) {
    .nav-link {
      .icon, .badge {
        display: none !important;
      }
    }
  }

  .nav-link-icon {
    position: relative;

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      width: 1rem;
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      font-size: .75rem;
      transform: translate3d(50%, -25%, 0);
    }
  }

  .nav-link {
    color: theme-color('primary');
    font-size: .6875rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    transition: $transition-nav-link;
    flex: 1;
    line-height: 1.1;
    min-width: 0;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    & + .nav-link {
      border-top: 1px solid gray('300');
    }

    @include hover-focus() {
      color: rgba(theme-color('secondary'), .8);
    }

    &.active {
      color: theme-color('secondary');
    }

    &.disabled {
      color: $navbar-dark-disabled-color;
      pointer-events: none;
      cursor: default;
    }
  }

  .dropdown {
    flex: 1;

    > .nav-link {
      width: 100%;
    }
  }

  @include media-breakpoint-up($split-view-breakpoint) {
    display: flex;
    top: $navbar-height;
    width: $side-navbar-width;
    right: auto;
    border-top: none;

    .nav-link {
      font-size: .875rem;
      padding: $nav-link-padding-y * 3.5 $nav-link-padding-x;
    }

    .dropright .dropdown-menu {
      margin-left: .25rem;
    }
  }

  @media (min-width: #{breakpoint-min($split-view-breakpoint, $grid-breakpoints)}) and (max-height: 650px) {
    .nav-link {
      padding: $nav-link-padding-y * 3 $nav-link-padding-x;
    }
  }
  @media (min-width: #{breakpoint-min($split-view-breakpoint, $grid-breakpoints)}) and (max-height: 600px) {
    .nav-link {
      padding: $nav-link-padding-y * 2.5 $nav-link-padding-x;
    }
  }

  hr {
    width: 100%;
    margin-top: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 3);
    border-color: gray('300');
  }

  &-item {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
