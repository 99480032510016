import { loadGlobals } from '@/util/globals'
import Vue from 'vue'
import FlagIcon from 'vue-flag-icon'
import { sync } from 'vuex-router-sync'
import * as Sentry from '@sentry/vue'
import { ModalBackdrop } from 'vue-bootstrap-modal'
import Portal from 'portal-vue'
import Icon from './components/Icon.vue'
import AnimatedNumber from './components/AnimatedNumber.vue'
import AnimatedInteger from './components/AnimatedInteger.vue'
import MediaObject from './components/list/MediaObject.vue'
import HeroCard from './components/detail/HeroCard.vue'
import AsideContainer from './components/layout/AsideContainer.vue'
import PinboardStatus from './components/PinboardStatus.vue'
import ShareWidget from './components/ShareWidget.vue'
import Loading from './components/Loading.vue'
import loadPolyfills from './polyfills'
import App from './App.vue'
import { router } from './router'
import store from './store'
import i18n from './i18n'
import * as filters from './filters'
import * as icons from './icons'
import {
  formatDistance, formatSpeed, formatTemperature, convertUnit, formatPressure
} from './util/unitFormatting'
import gtmEvent from './util/gtmEvent'
import { shopUrl } from './constants/urls'
import TrackClick from './directives/trackClick'
import './registerServiceWorker'

if (/\/(global-data)?\?accessToken=/.test(window.location.href)) {
  window.location.href = '/gallery/upload'
}

if (process.env.VUE_APP_SENTRY_DSN && process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration()
    ],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_SENTRY_ENV || process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
    debug: process.env.NODE_ENV === 'development',
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /cookielaw\.org/,
      /player\.redbull\.com/
    ],
    ignoreErrors: [
      /^Avoided redundant navigation to current location/,
      /^Redirected when going from/,
      /^Request aborted/,
      /^Network Error$/
    ]
  })
}

Vue.config.productionTip = false
Vue.config.ignoredElements = [
  'rbup-video-rbcom'
]

Vue.prototype.$title = process.env.VUE_APP_TITLE
Vue.prototype.$eventYear = process.env.VUE_APP_YEAR
Vue.prototype.$preRegisterDate = process.env.VUE_APP_PREREGISTER_DATE
// eslint-disable-next-line
Vue.prototype.$liveStreamReplayUrl = 'https://www.redbull.tv/video/AP-1UMJEX7U12111/a-global-race?playlist=playlists::3f81040a-2f31-4832-8e2e-545b1d39d173'
Vue.prototype.$resultsUrl = `/en/results?year=${process.env.VUE_APP_YEAR}`
Vue.prototype.$appLocationPageUrl = '/locations/app'
Vue.prototype.$convertUnit = convertUnit
Vue.prototype.$formatDistance = formatDistance
Vue.prototype.$formatSpeed = formatSpeed
Vue.prototype.$formatTemperature = formatTemperature
Vue.prototype.$formatPressure = formatPressure
Vue.prototype.$gtmEvent = gtmEvent
Vue.prototype.$youtubePromoUrl = 'https://www.youtube.com/watch?v=5sZ6Axzmzok'
Vue.prototype.$youtubeUrl = 'https://www.youtube.com/user/WingsForLifeWorldRun'
Vue.prototype.$shopUrl = shopUrl

Vue.use(FlagIcon)
Vue.use(Portal)
Object.keys(filters)
  .forEach((key) => {
    Vue.filter(key, filters[key])
  })

Object.entries(icons)
  .forEach(([name, definition]) => {
    Vue.component(`Icon${name}`, definition)
  })
Vue.component('ModalBackdrop', ModalBackdrop)
Vue.component('Icon', Icon)
Vue.component('AnimatedNumber', AnimatedNumber)
Vue.component('AnimatedInteger', AnimatedInteger)
Vue.component('AsideContainer', AsideContainer)
Vue.component('MediaObject', MediaObject)
Vue.component('HeroCard', HeroCard)
Vue.component('PinboardStatus', PinboardStatus)
Vue.component('ShareWidget', ShareWidget)
Vue.component('Loading', Loading)
Vue.directive('TrackClick', TrackClick)

// sync route to vuex store
sync(store, router)

loadGlobals()

/* eslint-disable no-new */
loadPolyfills()
  .then(() => {
    new Vue({
      el: '#app',
      router,
      store,
      i18n,
      ...App
    })
  })
