<template>
  <drop-down
    :value="value"
    :placement="placement"
    class="language-menu"
    @change="$emit('change', $event)"
  >
    <button
      slot="activator"
      :class="themedClass('btn-outline')"
      type="button"
      class="btn border-0"
    >
      <icon name="language"/>
      <small
        v-if="compact"
        class="ml-2 text-uppercase font-weight-bold"
      >
        {{ locale }}
      </small>
      <span
        v-if="!compact"
        class="ml-2"
      >
        {{ $options.languages[locale] }}
      </span>
    </button>
    <router-link
      v-for="(title, iso) in $options.languages"
      :key="iso"
      :class="{ active: iso === locale }"
      :to="{
        ...$route,
        params: {
          ...$route.params,
          lang: iso
        }
      }"
      class="dropdown-item"
    >
      {{ title }}
    </router-link>
  </drop-down>
</template>

<script>
import DropDown from '@/components/DropDown.vue'
import languages from '@/constants/languages'
import themeable from '@/mixins/themeable'

export default {
  name: 'LanguageSelect',
  languages,
  components: { DropDown },
  mixins: [themeable],
  model: {
    event: 'change'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'right'
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    locale() {
      this.$emit('change', false)
    }
  }
}
</script>

<style lang="scss">
  .language-menu {
    .dropdown-menu {
      max-height: 17rem;
      overflow: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }
</style>
