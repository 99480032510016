import Vue from 'vue'

export const globals = Vue.observable({
  globalPartners: undefined,
  presentingPartner: undefined,
  sponsors: undefined
})

export async function loadGlobals() {
  try {
    const response = await fetch(`${process.env.VUE_APP_MAIN_API_URL}config`)
    Object.assign(globals, await response.json())
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('failed to fetch global config', e)
  }
}
