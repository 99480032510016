<script lang="ts">
import { api } from '@/api'

export default {
  name: 'BaseFooter',
  data() {
    return {
      terms: null
    }
  },
  computed: {
    menu() {
      return this.terms?.map(m => ({
        name: m.name,
        href: m.policies[0].url
      }))
    }
  },
  async mounted() {
    const termsFromApi = await api.get('terms')
    this.terms = termsFromApi.data
  }
}
</script>

<template>
  <ul :class="['nav nav-gray mb-md-4 mx-auto mt-auto']">
    <li
      v-for="{ name, href } in menu"
      :key="name"
      class="nav-item"
    >
      <a
        class="nav-link"
        target="_blank"
        :href="href"
        v-text="name"
      />
    </li>
    <li
      class="nav-item"
    >
      <a
        id="ot-sdk-btn"
        v-t="`menu.cookieSettings`"
        href="javascript:OneTrust.ToggleInfoDisplay();"
        class="nav-link"
        title="Cookie Einstellungen"
      />
    </li>
  </ul>
</template>
