<template>
  <div
    :class="{ dropright: right }"
    class="dropdown"
    @mouseover.stop="onMouseOver"
    @mouseout.stop="onMouseOut"
  >
    <slot name="activator"/>
    <transition :name="transition">
      <div
        v-show="show"
        ref="menu"
        class="dropdown-menu show"
      >
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
import Popper from 'popper.js'
import delayable from '@/mixins/delayable'

const MODEL_EVENT = 'change'

export default {
  name: 'DropDown',
  mixins: [delayable],
  model: {
    event: MODEL_EVENT
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'auto'
    },
    transition: {
      type: String,
      default: 'fade'
    }
  },
  data() {
    return {
      popper: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit(MODEL_EVENT, value)
      }
    },
    $placement() {
      const { placement } = this
      if (placement !== 'auto') {
        return placement
      }

      if (this.right) {
        return 'right'
      }

      return placement
    }
  },
  updated() {
    const { popper } = this
    if (popper) {
      popper.options.placement = this.$placement
      popper.scheduleUpdate()
    }

    this.createPopper()
  },
  mounted() {
    this.createPopper()
  },
  beforeDestroy() {
    const { popper } = this
    if (popper) {
      popper.destroy()
      this.popper = null
    }
  },
  methods: {
    createPopper() {
      this.popper = new Popper(this.$el, this.$refs.menu, {
        placement: this.$placement,
        modifiers: {
          applyStyle: {
            onLoad: false
          }
        }
      })
    },
    onMouseOver() {
      this.show = true
      clearTimeout(this.$options.timer)
    },
    onMouseOut() {
      this.$options.timer = this.delay(() => {
        this.show = false
      })
    }
  }
}
</script>

<style lang="scss">

</style>
