import * as modes from '@/constants/modes'
import * as units from '@/constants/units'
import {
  SET_REFRESH, SET_MODE, SET_TICKRATE, SET_UNITS, SHOW_COUNTERS, SET_FEATURES,
  SET_RACE_FINISHED
} from '@/store/mutation-types'
import languages from '@/constants/languages'

const unitsDistance = {
  [units.METRIC]: 'km',
  [units.IMPERIAL]: 'mi'
}

const defaultLocale = (navigator.language || navigator.userLanguage).substr(0, 2)

export default {
  state() {
    return {
      refresh: 0,
      locale: defaultLocale in languages ? defaultLocale : 'en',
      mode: process.env.VUE_APP_MODE,
      tickrate: 60,
      units: units.METRIC,
      showCounters: true,
      /**
       * @type{RunningEventFeatures|undefined}
       */
      features: undefined,
      raceFinished: false
    }
  },
  mutations: {
    [SET_REFRESH](state, count) {
      const oldRefresh = state.refresh
      state.refresh = count

      if (oldRefresh && count > oldRefresh && typeof window !== 'undefined') {
        window.location.reload(true) // reload without cache
      }
    },
    [SET_MODE](state, mode) {
      if (mode in modes) {
        state.mode = mode
      }
    },
    [SET_TICKRATE](state, tickrate) {
      state.tickrate = Number(tickrate)
    },
    [SET_UNITS](state, unit) {
      if (unit in units) {
        state.units = unit
      }
    },
    [SHOW_COUNTERS](state, show) {
      state.showCounters = show
    },
    [SET_FEATURES](state, features) {
      state.features = features
    },
    [SET_RACE_FINISHED](state, raceFinished = false) {
      state.raceFinished = raceFinished
    }
  },
  actions: {
    setUnits({ commit }, unit) {
      commit(SET_UNITS, unit)
    }
  },
  getters: {
    state: state => state,
    locale: state => state.locale,
    mode: (state, { startTime }) => {
      if (state.mode) {
        return state.mode
      }

      const date = new Date()
      const endDate = new Date()
      endDate.setTime(startTime.getTime() + (5 * 60 * 60 * 1000)) // endDate = 16:00 UTC
      if (state.raceFinished || date > endDate) {
        return modes.POST
      }

      if (date > startTime) {
        return modes.LIVE
      }

      return modes.PRE
    },
    tickrate: state => state.tickrate,
    units: state => state.units,
    unitDistance: state => unitsDistance[state.units],
    unitSpeed: state => `${unitsDistance[state.units]}/h`,
    showCounters: state => state.showCounters,
    hasFeature: state => feature => state.features && feature in state.features && state.features[feature],
    getFeature: state => feature => (state.features && feature in state.features ? state.features[feature] : null)
  }
}
