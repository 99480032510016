<template>
  <media-object v-bind="itemProps">
    <template #after>
      <StatusBadge
        v-if="[$options.modes.LIVE, $options.modes.POST].includes(mode)"
        :value="value.status"
        type="location"
      />
    </template>
  </media-object>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import { mapGetters } from 'vuex'
import * as status from '@/constants/location'
import mediaObjectMixin from '@/mixins/mediaObject'
import { PRE } from '@/constants/modes'
import * as modes from '@/constants/modes'

export default {
  name: 'LocationListItem',
  status,
  modes,
  components: {
    StatusBadge
  },
  mixins: [mediaObjectMixin],
  computed: {
    ...mapGetters([
      'mode'
    ]),
    itemProps() {
      const location = this.value
      const count = location.runners.total.total

      return {
        key: location.id,
        title: location.title,
        text: this.mode === PRE ? '' : this.$tc('location.participants.count', count, [count]),
        image: location.image,
        imageShape: 'square',
        url: location.url
      }
    }
  }
}
</script>

<style lang="scss">

</style>
