export default function loadPolyfills() {
  const polyfills = []
  const isEdge17 = (window.navigator.userAgent.match(/Edge\/([0-9]+)/) || [])[1] < 18
  if (isEdge17) {
    delete window.fetch
  }

  if (!('fetch' in window)) {
    polyfills.push(import(/* webpackChunkName: "polyfills/fetch" */ 'isomorphic-fetch'))
  }

  if (!('Intl' in window)) {
    polyfills.push(import(/* webpackChunkName: "polyfills/intl" */ 'intl'))
    polyfills.push(import(/* webpackChunkName: "polyfills/intl" */ 'intl/locale-data/jsonp/en'))
  }

  if (!('matchMedia' in window)) {
    polyfills.push(import(/* webpackChunkName: "polyfills/matchMedia" */ 'matchmedia-polyfill'))
  }

  if (!('URLSearchParams' in window)) {
    polyfills.push(import(/* webpackChunkName: "polyfills/URLSearchParams" */ 'url-search-params-polyfill'))
  }

  if (!('IntersectionObserver' in window)) {
    polyfills.push(import(/* webpackChunkName: "polyfills/IntersectionObserver" */ 'intersection-observer'))
  }

  return Promise.all(polyfills)
}
