/* eslint-disable no-param-reassign */
import locationImage from '@/assets/avatar/location.gif'
import avatarM from '@/assets/avatar/male.gif'
import avatarF from '@/assets/avatar/female.gif'
import interpolate from '../util/distance'

export default function transformParticipant({
  firstname,
  lastname,
  fullname: fn,
  nationality = '',
  avatar,
  image = locationImage,
  gender,
  url,
  raised,
  distance,
  positionTs,
  status,
  ...participant
}) {
  if (!avatar || avatar.includes('avatar_neutral')) {
    if (gender === 'f') {
      avatar = avatarF
    } else {
      avatar = avatarM
    }
  } else {
    avatar = `/uploads/fit/160/160/${avatar}`
  }

  const fullname = [firstname, lastname]

  // remove null values here for now
  Object.keys(participant).forEach((key) => {
    if (participant[key] === null) {
      delete participant[key]
    }
  })

  return {
    firstname,
    lastname,
    fullname: fullname.join(' '),
    nationality,
    avatar,
    image,
    gender,
    url: { name: 'participants.detail', params: { id: participant.id } },
    raised,
    distance: interpolate(distance, positionTs, status),
    status,
    ...participant
  }
}
