import ParticipantListItem from '@/components/list/ParticipantListItem.vue'
import TeamListItem from '@/components/list/TeamListItem.vue'
import LocationListItem from '@/components/list/LocationListItem.vue'
import { mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash-es'
import transformParticipant from '@/worker/transformer/transformParticipant'
import transformTeam from '@/worker/transformer/transformTeam'

const actions = {
  participants: {
    transform: transformParticipant,
    store: 'addParticipant'
  },
  teams: {
    transform: transformTeam,
    store: 'addTeam'
  }
}

export default {
  minQLength: 1,
  map: {
    participants: ParticipantListItem,
    teams: TeamListItem,
    locations: LocationListItem
  },
  data() {
    return {
      loading: false,
      results: {
        participants: [],
        teams: [],
        locations: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'locations'
    ]),
    doSearch() {
      return debounce(this.$doSearch, 300)
    }
  },
  watch: {
    q: 'doSearch'
  },
  methods: {
    ...mapActions([
      'addParticipant',
      'addTeam'
    ]),
    async $doSearch(q) {
      if (q.length < this.$options.minQLength) {
        return
      }

      const { results } = this

      try {
        this.loading = true
        const response = await fetch(`${process.env.VUE_APP_LIVE_API_URL}search?q=${encodeURIComponent(q)}`)

        if (!response.ok) {
          throw Error(response.statusText)
        }

        // we can't stop fetch yet, so we check if this request still matches the user's search
        if (q !== this.q) {
          return
        }

        const searchResults = await response.json()

        Object.entries(searchResults).forEach(([type, values]) => {
          const records = results[type]
          const recordActions = actions[type]

          records.splice(0, records.length)
          Array.prototype.push.apply(records, recordActions.transform ? values.map(recordActions.transform) : values)

          if (recordActions.store) {
            this[recordActions.store](records)
          }
        })

        if (this.q.length) {
          results.locations = this.locations.filter(({ keywords }) => keywords.includes(this.q.toLowerCase()))
        } else {
          results.locations = []
        }

        this.loading = false

        this.$gtmEvent({
          event: 'gaSeaEvent',
          eventCategory: 'Search',
          eventAction: 'Search Query',
          eventLabel: q
        })
        this.$gtmEvent({
          event: 'ga4Event',
          event_name: 'view_search_results',
          view_search_results: {
            value: q,
            content_type: 'Live search'
          }
        })
        this.$gtmEvent({
          event: 'gaEvent',
          event_category: 'Search',
          eventAction: 'Search Query',
          eventLabel: q
        })
      } catch (e) {
        this.loading = false
        // handle error
      }
    }
  }
}
