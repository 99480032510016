<template>
  <div
    :class="[
      `media media-object p-3 my-${margin}`,
      $style.media,
      imageShape === 'square' && $style.imgSquare
    ]"
  >
    <router-link
      :to="url"
      class="position-relative"
    >
      <slot name="beforeImg"/>
      <img
        :src="imgSrc"
        :alt="title"
        :class="[
          'align-self-center mr-3',
          imageShape === 'square' ? 'rounded-left' : 'rounded-circle'
        ]"
        @error="replaceWithDefaultImage"
      >
    </router-link>
    <router-link
      :to="url"
      class="media-body align-self-center"
    >
      <h5
        class="mt-0 mb-0 fs-15 text-uppercase"
        v-text="title"
      />
      <slot>
        <small
          class="text-condensed text-uppercase font-weight-bold"
          v-text="text"
        />
      </slot>
    </router-link>
    <slot name="after"/>

    <pinboard-status
      v-if="pinnable"
      v-bind="pinnable"
      class="ml-1"
    />
  </div>
</template>

<script>
import team from '@/assets/avatar/team.gif'

export default {
  name: 'MediaObject',
  props: {
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    },
    pinnable: {
      type: Object,
      default: null
    },
    image: {
      type: String,
      default: undefined
    },
    margin: {
      type: Number,
      default: 2
    },
    imageShape: {
      type: String,
      default: undefined
    },
    // vue-router to-args
    url: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      imgSrc: this.image
    }
  },
  methods: {
    replaceWithDefaultImage() {
      this.imgSrc = team
    }
  }
}
</script>

<style lang="scss" module>
.media {
  border-radius: $border-radius;
  background-color: white;
  line-height: 1;
  align-items: center;
  box-shadow: $box-shadow;

  img {
    width: 2.5rem;
    height: 2.5rem;
    background-color: gray('200');
  }

  a {
    transition: $transition-base;
    @include hover-focus() {
      color: theme-color('secondary');
      text-decoration: none;
    }
  }
}

.imgSquare {
  a {
    margin: map-get($spacers, 3) * -1 map-get($spacers, 3) map-get($spacers, 3) * -1 map-get($spacers, 3) * -1;
  }

  img {
    width: 3.5rem;
    height: 100%;
    min-height: 4.25rem;
    object-fit: cover;
  }
}
</style>
