import gtmEvent from '@/util/gtmEvent'

export default {
  bind(el, { value }) {
    let type = value
    let label
    if (Array.isArray(value)) {
      [type, label] = value
    }

    function onClick(e) {
      gtmEvent({
        event: 'ga4Event',
        event_name: 'click',
        click: {
          value: label || e.currentTarget.innerText,
          click_url: e.currentTarget.href,
          method: `Outbound: ${JSON.stringify(el.target === '_blank')}`,
          content_type: type
        }
      })
      gtmEvent({
        event: 'gaEvent',
        eventCategory: el.target === '_blank' ? 'Outbound Link' : type,
        eventAction: label || e.currentTarget.innerText,
        eventLabel: e.currentTarget.href
      })
    }

    if (el.tagName === 'A') {
      el.addEventListener('click', onClick)

      // eslint-disable-next-line no-param-reassign
      el.$vTrackClick = () => {
        el.removeEventListener('click', onClick)
      }
    } else {
      Array.from(el.querySelectorAll('a')).forEach((link) => {
        link.addEventListener('click', onClick)
      })

      // eslint-disable-next-line no-param-reassign
      el.$vTrackClick = () => {
        Array.from(el.querySelectorAll('a')).forEach((link) => {
          link.removeEventListener('click', onClick)
        })
      }
    }
  },
  unbind(el) {
    el.$vTrackClick()
  }
}
