<template>
  <div class="embed-responsive embed-responsive-16by9 bg-dark">
    <rbup-video-rbcom
      class="embed-responsive-item"
      :asset-id="videoId"
      cornerbug="hidden"
      v-bind="$attrs"
      :autoplay="autoplay"
    />
  </div>
</template>

<script>
let loaded = false
function loadScript() {
  if (loaded) {
    return Promise.resolve()
  }

  return new Promise((resolve) => {
    const script = document.createElement('script')
    script.type = 'module'
    script.crossOrigin = 'anonymous'
    script.src = '//player.redbull.com/v2/rbup-video-rbcom-webcomponent/latest/rbup-video-rbcom-webcomponent.es.js'
    script.onload = () => {
      loaded = true
      resolve()
    }

    document.body.appendChild(script)
  })
}

// docs: https://platforms.redbull.com/develop/custom-development/video-player-integration/rbcom-video-integration
export default {
  name: 'RedBullVideo',
  props: {
    videoId: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    }
  },
  beforeMount() {
    loadScript()
  }
}
</script>
