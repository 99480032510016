import { LIVE, mode2Id } from '@/constants/modes'
import { mapGetters } from 'vuex'

export default {
  maxItems: 4,
  maxItemsSm: 3,
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  inject: ['media'],
  computed: {
    ...mapGetters([
      'mode'
    ]),
    maxItems() {
      if (this.media.lg) {
        return this.$options.maxItems
      }
      return this.$options.maxItemsSm
    },
    menu() {
      return this.items
        .filter(({
          meta: {
            menu = false,
            modes
          } = {}
        }) => {
          const disabled = modes ? !modes.includes(this.mode) : false
          return menu && !disabled
        })
        .sort(this.sortMenuItems)
        .map(({
          path,
          name,
          redirect = {},
          meta: {
            icon,
            divider,
            href
          } = {}
        }) => ({
          is: href ? 'a' : 'router-link',
          name: name || redirect.name,
          nameChoice: name === 'global' ? mode2Id(this.mode) : undefined,
          url: href || { name, params: { lang: this.$i18n.locale } } || path,
          icon,
          divider,
          target: href ? '_blank' : undefined
        }))
    }
  },
  methods: {
    sortMenuItems(a, b) {
      const prioritizeOne = 'follow'

      if (this.$store.getters.mode === LIVE) {
        if (a.name === prioritizeOne) return -1
        if (b.name === prioritizeOne) return 1
      }

      return 0
    }
  }
}
