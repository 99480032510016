<template>
  <div class="navbar-container bg-white d-flex flex-row fixed-top">
    <header class="navbar navbar-light w-100">
      <div
        class="d-flex flex-row flex-nowrap"
        style="gap: 2rem"
      >
        <a
          class="navbar-brand"
          href="/live"
        >
          <img
            v-if="globals.presentingPartner"
            :alt="$title"
            :title="$title"
            :src="`/uploads/original/${globals.presentingPartner.logoHorizontal}`"
            :class="$style.logo"
          >
          <img
            v-else
            :alt="$title"
            :title="$title"
            :class="$style.logo"
            src="@/assets/logo.png"
            width="205"
            height="96"
          >
        </a>
        <div
          :class="[
            'text-uppercase font-weight-bold text-condensed d-none d-lg-flex flex-column mr-auto justify-content-center'
          ]"
        >
          <div
            class="d-flex align-items-center"
            style="gap: 0.8rem"
          >
            <div :class="$style.radio"><span/></div>
            <div
              class="bg-secondary text-white rounded-pill px-2 font-sm d-flex  align-items-center"
            >
              <span
                style="height: 4px; width:4px; background-color:white"
                class="rounded-circle d-inline-block mr-1"
              />
              {{ $t('label.live') }}
            </div>
            {{ $t('navbar.live') }}
          </div>
          <a
            :href="`/${$i18n.locale}`"
            class="text-uppercase d-flex align-items-center"
            style="text-transform: none;gap: 0.8rem;"
            @click="setRedirectInhibit"
          >
            <div
              class="border-primary"
              :class="[$style.radio]"
            />
            <span v-text="$t('navbar.website')"/>
          </a>
        </div>
      </div>

      <div :class="['ml-lg-auto mt-2 mt-lg-0 mr-lg-4 order-last order-lg-0', $style.search]">
        <SearchInput
          id="search"
          name="search"
          :value="q"
          :placeholder="$t('search.placeholder')"
          input-class="form-control-no-zoom"
          @focus="$emit('toggleSearch', true)"
          @blur="$emit('toggleSearch', !!$event.target.value)"
          @clear="$emit('update:q', ''); $emit('toggleSearch', false)"
          @keydown.esc="$emit('toggleSearch', false)"
          @input="$emit('update:q', $event)"
        />
      </div>

      <GlobalSponsor class="ml-auto ml-lg-0"/>

      <div>
        <router-link
          v-if="hasFeature('boostRunner') && mode !== $options.modes.POST"
          :to="{ ...$route, query: { ...$route.query, search: $options.searchModes.DONATE_DISTANCE } }"
          class="btn btn-secondary donate-button d-none d-lg-block"
          active-class="none"
        >
          <icon
            src="@/assets/icons/donate.svg"
            sm
            class="mr-2"
          />
          <span>{{ $t('donate.perKm') }}</span>
        </router-link>

        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="$emit('change', true)"
        >
          <span class="navbar-toggler-icon"/>
        </button>
      </div>
    </header>
  </div>
</template>

<script>
import GlobalSponsor from '@/components/GlobalSponsor.vue'
import SearchInput from '@/components/SearchInput.vue'
import { globals } from '@/util/globals'
import { mapGetters } from 'vuex'
import * as modes from '@/constants/modes'
import * as searchModes from '@/constants/searchModes'

export default {
  name: 'NavBar',
  modes,
  searchModes,
  components: {
    GlobalSponsor,
    SearchInput
  },
  props: {
    q: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      globals
    }
  },
  computed: {
    ...mapGetters([
      'mode',
      'startTime',
      'catcherCarDistance',
      'unitDistance',
      'runnersIn',
      'runnersTotal',
      'hasFeature'
    ]),
    live() {
      return this.mode === modes.LIVE
    }
  },
  methods: {
    setRedirectInhibit() {
      window.sessionStorage.setItem('lxRedirectInhibit', Date.now().toString())
      window.localStorage.setItem('lxRedirectInhibit', Date.now().toString())
    }
  }
}
</script>

<style lang="scss">
@import "../../scss/bootstrap/custom";

.navbar-container {
  height: $navbar-height-sm;
  padding: env(safe-area-inset-top) 0 0 env(safe-area-inset-left);

  .donate-banner-btn {
    padding-right: env(safe-area-inset-right);
  }

  @include media-breakpoint-up($split-view-breakpoint) {
    height: $navbar-height;
    box-shadow: $navbar-shadow;
  }
}

@include media-breakpoint-down(lg) {
  .navbar {
    align-content: center;
  }
  .navbar-brand {
    img {
      width: auto;
      height: 42px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    padding-right: 0;
  }
  .navbar-brand {
    img {
      width: auto;
      height: 34px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar {
    padding-right: map-get($spacers, 2) !important;
  }

  .navbar-brand {
    margin-right: map-get($spacers, 1) !important;
  }
}

.countdown {
  line-height: 1.2; // prevent navbar from growing

  @include media-breakpoint-down(xs) {
    .font-xl {
      font-size: 1.25rem !important;
    }

    small, .small {
      font-size: .75rem !important;
    }
  }
}

.navbar-stats-item {
  line-height: 1;

  .icon {
    vertical-align: top;
  }

  .icon-cc {
    width: 44px;
    height: 44px;
    margin-top: -12px;
    margin-bottom: -8px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $font-size-sm;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    font-size: $font-size-base * 1.5; // -> .font-xl
  }
}

.navbar-partner {
  .img-fluid {
    max-width: 3rem;
  }

  @include media-breakpoint-down(xs) {
    max-width: 4rem;
    line-height: 1.3;

    .img-fluid {
      max-width: 2.5rem;
    }
  }
}
</style>
<style lang="scss" module>
.search {
  width: 100%;

  @include media-breakpoint-up($split-view-breakpoint) {
    width: auto;
  }

  :global(.form-control) {
    @include media-breakpoint-up($split-view-breakpoint) {
      min-width: 21rem;
    }
  }
}

.logo {
  width: auto;
  height: 3rem;
}
.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  border-radius: 9999px;
  border: 1px solid $secondary;

  :first-child {
    height: 0.375rem;
    width: 0.375rem;
    border-radius: 9999px;
    background-color: $secondary;
  }
}

</style>
