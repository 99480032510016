<template>
  <figure :class="[$style.parent, 'mb-0 position-relative']">
    <img
      :src="runner.avatar"
      :alt="runner.fullname"
      :title="runner.fullname"
      :class="[$style.avatar, 'rounded-circle']"
      :width="size"
      :height="size"
    >
    <span
      v-if="badge"
      :class="$style.badge"
    >{{ badge }}</span>
  </figure>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    runner: {
      type: Object,
      required: true
    },
    sm: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    badge: {
      type: String,
      default: undefined
    }
  },
  computed: {
    size() {
      if (this.sm) {
        return 40
      }

      if (this.lg) {
        return 120
      }

      return 80
    }
  }
}
</script>

<style lang="scss" module>
.parent {
  color: $white;
}

.avatar {
  color: inherit;
  border: 2px solid currentColor;
  display: block; // fix broken layout when images not loaded
}

.badge {
  position: absolute;
  top: .25rem;
  left: 65%;
  color: $white;
  font-weight: $font-weight-bold;
  font-size: .625rem;
  white-space: nowrap;
  padding: .125rem .375rem;
  @include border-radius($border-radius);
  background-color: theme-color('primary');
  border: 1px solid $white;
}
</style>
