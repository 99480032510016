import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'Cc',
  functional: true,
  render: function (h, ctx) {
    return h(
      "svg",
      _mergeJSXProps([{
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
      }, ctx.data]),
      [h("path", {
        attrs: { d: "M9.38 17.13c0 1.02-.83 1.87-1.83 1.87-1.03 0-1.85-.85-1.85-1.87s.83-1.87 1.83-1.87c1.02 0 1.85.83 1.85 1.87zm14.56-1.19c-.1.7-.88.95-.88.95.03-.07.1-2.34-2.21-2.54-.15 0-.25-.02-.35-.02-1.7 0-2.51 1.2-2.43 2.99h-3.13c-2.26 0-4.91 0-4.91-.05.1-1.2-.35-2.79-2.41-2.79h-.33c-2.16.15-2.33 2.24-2.33 2.66 0 0-1.83-.57-1.98-1.12-.15-.52-.18-1.22-.18-1.22s.2-.4.35-.42c.15-.05-.15-1.69.05-2.12.25-.42 2.06-2.89 2.06-2.89s0-.11-.02-.22v-.01l-2.41-.01v.5h.03c0 .17-.33.37-.45.37H.3c-.12.01-.3-.16-.3-.36v-.53c0-.15.1-.5.2-.6v-.02l4.07-.01c.6-.22 1.26-.54 1.97-.86 1.73-.77 3.71-1.6 5.87-1.62 1.25 0 2.63.47 3.74 1.34.54.44 1.1.85 1.47 1.11l2.35-.01c.01 0 .01-.01.02-.01l2.13-.02h.03c.03 0 .05.02.07.02.18.1.43.47.43.62v.52c0 .15-.38.35-.5.35h-2.16c-.13 0-.18-.15-.18-.35v-.48l-4.5.01-.01.02c.01 0 .01.01.02.01H15c1.08.75 3.28 2.37 3.28 2.37s4.24.27 5.14 1.3c.43.52.28 1.02.35 1.05.09.04.34.47.17 2.09zM5.23 8.48l3.75-.01 7.55-.02c-.26-.27-.76-.67-1.14-.98-1-.8-2.11-1.1-3.26-1.1-2.06 0-3.94.85-5.62 1.57-.44.2-.87.38-1.28.54zm2.37.88s-1.53.12-1.68.17c-.33.12-.85 1.15-.93 1.32-.05.15-.18.5.35.5.49 0 1.43.04 1.56.05l.7-2.04zm3.93 2.12l-.5-2.27-2.96.08-.68 2.14 4.14.05zm5.94.12s-2.01-1.84-3.48-2.22c-.9-.2-2.11-.2-2.11-.2l.68 2.29 4.91.13zm3.19 3.46c-1 0-1.83.85-1.83 1.87s.83 1.87 1.83 1.87 1.83-.85 1.83-1.87c0-1.04-.83-1.87-1.83-1.87z" }
      })]
    );
  }
};