/* eslint-disable camelcase,no-param-reassign */
import UnknownRunner from '@/Objects/UnknownRunner'
import transformLocation from './transformLocation'
import transformParticipant from './transformParticipant'

function extractParticipants(locations) {
  const participants = []
  const leaders = {
    m: [],
    w: []
  }
  /* const distances = {
    m: 0,
    w: 0
  } */

  locations.forEach(({
    id,
    leading_man,
    leading_man_speed,
    leading_man_distance,
    leading_woman,
    leading_woman_speed,
    leading_woman_distance
  }, i, locs) => {
    if (leading_man === null) {
      leading_man = {}
    }
    if (leading_man) {
      if (!leading_man.id) {
        leading_man = new UnknownRunner(leading_man)
        locs[i].leading_man = leading_man
      }
      leading_man.speed = leading_man_speed
      leading_man.distance = leading_man_distance
      leading_man.locationId = id
      leading_man.gender = 'm'
      const participant = transformParticipant(leading_man)
      participants.push(participant)
    }
    if (leading_woman === null) {
      leading_woman = {}
    }
    if (leading_woman) {
      if (!leading_woman.id) {
        leading_woman = new UnknownRunner(leading_woman)
        locs[i].leading_woman = leading_woman
      }
      leading_woman.speed = leading_woman_speed
      leading_woman.distance = leading_woman_distance
      leading_woman.locationId = id
      leading_woman.gender = 'f'
      const participant = transformParticipant(leading_woman)
      participants.push(participant)
    }

    /* if (leading_man && (!leader.m || leading_man.distance > distances.m)) {
      leader.m = leading_man.id
      distances.m = leading_man.distance
    }
    if (leading_woman && (!leader.w || leading_woman.distance > distances.w)) {
      leader.w = leading_woman.id
      distances.w = leading_woman.distance
    } */
  })

  return {
    participants,
    leaders
  }
}

function extractLeaders(leadersPerGender, { participants, leaders }) {
  if (leadersPerGender) {
    const m = leadersPerGender.m.map((p) => {
      const participant = transformParticipant(p)
      participants.push(participant)
      return p.id
    })

    const w = leadersPerGender.w.map((p) => {
      const participant = transformParticipant(p)
      participants.push(participant)
      return p.id
    })

    return {
      participants,
      leaders: {
        m: m.length ? m : [participants[0].id],
        w: w.length ? w : [participants[1].id]
      }
    }
  }

  return {
    participants,
    leaders
  }
}

export default function transformUpdates({
  refresh,
  startDate,
  globalDistance: carDistance,
  globalSpeed: carSpeed,
  locations,
  leaders,
  globals,
  features,
  raceFinished
}) {
  return {
    refresh,
    startDate,
    locations: locations.map(transformLocation),
    ...extractLeaders(leaders, extractParticipants(locations)),
    globals,
    carSpeed,
    carDistance,
    features,
    raceFinished
  }
}
