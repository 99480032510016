var render = function render(){var _vm=this,_c=_vm._self._c;return _c('drop-down',{staticClass:"language-menu",attrs:{"value":_vm.value,"placement":_vm.placement},on:{"change":function($event){return _vm.$emit('change', $event)}}},[_c('button',{staticClass:"btn border-0",class:_vm.themedClass('btn-outline'),attrs:{"slot":"activator","type":"button"},slot:"activator"},[_c('icon',{attrs:{"name":"language"}}),(_vm.compact)?_c('small',{staticClass:"ml-2 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(_vm.locale)+" ")]):_vm._e(),(!_vm.compact)?_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$options.languages[_vm.locale])+" ")]):_vm._e()],1),_vm._l((_vm.$options.languages),function(title,iso){return _c('router-link',{key:iso,staticClass:"dropdown-item",class:{ active: iso === _vm.locale },attrs:{"to":{
      ..._vm.$route,
      params: {
        ..._vm.$route.params,
        lang: iso
      }
    }}},[_vm._v(" "+_vm._s(title)+" ")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }