import Vue from 'vue'
import array from '@/util/array'
import RecordFragment from '@/store/modules/RecordFragment'
import transformParticipant from '@/worker/transformer/transformParticipant'
import { SET_PARTICIPANT, PATCH_PARTICIPANT } from '../mutation-types'

function setLocation(participant, getLocationById) {
  if (!participant) {
    return undefined
  }

  if (!('location' in participant)) {
    Object.defineProperty(participant, 'location', {
      enumerable: true, // Vue observable needs this
      configurable: true,
      get: () => getLocationById(participant.locationId)
    })
  }

  return participant
}

export default {
  state() {
    return {}
  },
  mutations: {
    [SET_PARTICIPANT](state, participants) {
      array(participants)
        .forEach((participant) => {
          if (!(participant.id in state && participant.constructor === RecordFragment)) {
            Vue.set(state, participant.id, participant)
          }
        })
    },
    [PATCH_PARTICIPANT](state, participants) {
      array(participants).forEach((participant) => {
        if (state[participant.id]) {
          Vue.set(state, participant.id, { ...state[participant.id], ...participant })
        } else {
          Vue.set(state, participant.id, participant)
        }
      })
    }
  },
  actions: {
    addParticipant({ commit }, participant) {
      commit(SET_PARTICIPANT, array(participant).map(p => new RecordFragment(p)))
    },
    async getParticipantById({ commit, state }, id) {
      /**
       * @type {import('@/types/Participant.js').Participant}
       */
      const participant = state[id]

      if (!participant || (participant && participant.incomplete)) {
        try {
          const response = await fetch(`${process.env.VUE_APP_LIVE_API_URL}participants/${id}`)
          const { data: p } = await response.json()
          commit(SET_PARTICIPANT, array(transformParticipant(p)))
          return p
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('failed to get participant', e)
        }
      }
      return participant
    }
  },
  getters: {
    participantById: (state, { locationById }) => id => setLocation(state[id], locationById),
    participants: (state, { locationById }) => Object.values(state)
      .map(participant => setLocation(participant, locationById))
  }
}
