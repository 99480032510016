export default class RecordFragment {
  constructor(data) {
    Object.assign(this, data)
  }

  // eslint-disable-next-line class-methods-use-this
  get incomplete() {
    return true
  }
}
