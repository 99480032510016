<template>
  <aside :class="$style.overlay">
    <div class="container py-3 safe-area-bottom">

      <loading
        v-if="loading"
        class="my-6"
      />
      <template v-else-if="!Object.entries(results).reduce((acc, [,records]) => acc + records.length, 0)">
        <div class="row my-5">
          <div
            v-if="mode === $options.modes.DONATE_DISTANCE"
            class="mx-auto text-center col-12 col-sm-10 col-md-8 col-lg-6"
          >
            <h2
              v-t="'search.donateDistance'"
              class="h5 mb-3"
            />
            <img
              src="@/assets/search/search-donate.png"
              :alt="$t('search.donateDistance')"
              width="126"
              height="114"
            >
          </div>

          <div
            v-if="mode === $options.modes.AUDIO_STICKER"
            class="mx-auto text-center col-12 col-sm-10 col-md-8 col-lg-6"
          >
            <h2
              v-t="'search.audioSticker'"
              class="h5 mb-3"
            />
            <img
              src="@/assets/search/search-audio-sticker.png"
              :alt="$t('search.audioSticker')"
              width="126"
              height="114"
            >
          </div>
        </div>
      </template>
      <template v-else>
        <section
          v-for="(records, type) in results"
          :id="type"
          :key="type"
          class="mb-3"
        >
          <template v-if="records.length">
            <h3
              v-t="`search.${type}`"
              class="h6 text-uppercase"
            />
            <transition-group
              name="fade"
              tag="div"
              appear
            >
              <component
                :is="$options.map[type]"
                v-for="record in records"
                :key="type + record.id"
                :value="record"
              />
            </transition-group>
          </template>
        </section>
      </template>
    </div>
  </aside>
</template>

<script>
import * as modes from '@/constants/searchModes'
import search from '@/mixins/search'

export default {
  name: 'SearchOverlay',
  modes,
  mixins: [search],
  props: {
    q: {
      type: String,
      default: ''
    }
  },
  computed: {
    mode() {
      const { search: mode = modes.NORMAL } = this.$route.query
      return mode
    }
  },
  mounted() {
    this.focus()

    if (this.q) {
      this.doSearch(this.q)
    }
  },
  methods: {
    focus() {
      const input = document.getElementById('search')
      if (input) {
        input.focus()
      }
    }
  }
}
</script>

<style lang="scss" module>
.overlay {
  position: absolute !important; // make important to fix hide animation
  top: $navbar-height-sm;
  left: 0;
  right: 0;
  //bottom: 0;
  height: subtract(100vh, $navbar-height-sm);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: $body-bg;
  z-index: $zindex-fixed - 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up($split-view-breakpoint) {
    top: $navbar-height;
    left: $side-navbar-width;
    width: subtract(100vw, $side-navbar-width);
    min-height: subtract(100vh, $navbar-height);
  }
}
</style>
