import { POST } from '@/constants/modes'

/**
 * checks if app mode is post race
 *
 * @param status {string} status
 *
 * @return {boolean}
 */
export default function hasStatusBadge(status) {
  const isPostRace = String(process.env.VUE_APP_MODE).toLowerCase() === POST.toLowerCase()
  const disallowedStatus = ['running', 'prerace', 'registered']
  const isDisallowedStatus = disallowedStatus.includes(status.toLowerCase())

  return !isPostRace || !isDisallowedStatus
}
