const factors = [
  { distance: 500, factor: 0, limit: 0 },
  { distance: 10000, factor: 0.8, limit: 750 },
  { distance: 15000, factor: 0.6, limit: 500 },
  { distance: 20000, factor: 0.4, limit: 250 }
]

const paceProfile = [
  { speed: 0 / 3.6, duration: 30 * 60 },
  { speed: 14 / 3.6, duration: 30 * 60 },
  { speed: 15 / 3.6, duration: 30 * 60 },
  { speed: 16 / 3.6, duration: 30 * 60 },
  { speed: 17 / 3.6, duration: 30 * 60 },
  { speed: 18 / 3.6, duration: 30 * 60 },
  { speed: 22 / 3.6, duration: 30 * 60 },
  { speed: 26 / 3.6, duration: 30 * 60 },
  { speed: 30 / 3.6, duration: 30 * 60 },
  { speed: 34 / 3.6, duration: 2 * 60 * 60 }
]

function getFactor(distance) {
  let result = { factor: 0, limit: 0 }
  factors.some((factor) => {
    if (distance < factor.distance) {
      result = factor
      return true
    }
    return false
  })

  return result
}

function getDiffFromStart(timestamp) {
  const startTime = (new Date(process.env.VUE_APP_EVENT_DATE)).getTime() / 1000

  return timestamp - startTime
}

function getSpeed(distance, timestamp) {
  return Math.max(0, distance / getDiffFromStart(timestamp))
}

function getSecondsSinceUpdate(timestamp) {
  const now = (new Date()).getTime() / 1000

  return now - timestamp
}

function estimateFinishDistance(athleteDistance, timestamp, athleteSpeed) {
  if (getDiffFromStart(timestamp) <= 0) {
    return athleteDistance
  }

  let finishDistance = athleteDistance
  let carCalcDistance = 0
  let athleteCalcDistance = 0

  paceProfile.some((car) => {
    const carIntervalDistance = car.speed * car.duration
    const athleteIntervalDistance = athleteSpeed * car.duration

    if (carCalcDistance + carIntervalDistance > athleteCalcDistance + athleteIntervalDistance) {
      const diffDist = athleteCalcDistance - carCalcDistance
      const diffSpeed = athleteSpeed - car.speed

      finishDistance = athleteCalcDistance + Math.abs(athleteSpeed * (diffDist / diffSpeed))

      return true
    }

    athleteCalcDistance += athleteIntervalDistance
    carCalcDistance += carIntervalDistance

    return false
  })

  return finishDistance
}

export default function interpolate(distance, timestamp, status) {
  const factor = getFactor(distance)
  if (!timestamp || !factor || status !== 'running') {
    return distance
  }

  const meterPerSecond = getSpeed(distance, timestamp)
  const secondsSinceUpdate = getSecondsSinceUpdate(timestamp)
  const diffDistance = Math.min(factor.limit, meterPerSecond * secondsSinceUpdate * factor.factor)

  const calculatedDistance = distance + diffDistance
  const estimatedDistance = estimateFinishDistance(distance, timestamp, meterPerSecond)

  return Math.min(Math.max(distance, estimatedDistance), calculatedDistance)
}
