// App modes

export const PRE = 'PRE'
export const WARMUP = 'WARMUP'
export const LIVE = 'LIVE'
export const POST = 'POST'

export function mode2Id(mode) {
  if (mode === WARMUP) {
    return 1
  }

  return [PRE, LIVE, POST].indexOf(mode)
}
