import * as units from '@/constants/units'
import number from '@/filters/number'
import i18n from '@/i18n'

export function convertUnit(km) {
  if (!this) {
    throw new Error('You can\'t use this function directly. Use the function bound to the Vue instance instead!')
  }

  const unit = this.$store.getters.units
  return unit === units.METRIC ? km : ((km * 6) / 10)
}

export function formatDistance(value, showUnit = true) {
  const unit = this.$store.getters.unitDistance
  const unitString = showUnit ? ` ${unit}` : ''
  const { locale } = this.$i18n
  return `${number(this.$convertUnit(value), locale)}${unitString}`
}

export function formatSpeed(value, showUnit = true) {
  const unit = this.$store.getters.unitSpeed
  const unitString = showUnit ? ` ${unit}` : ''
  const { locale } = this.$i18n
  return `${number(this.$convertUnit(value), locale)}${unitString}`
}
const formatNumber = numberToFormat => number(numberToFormat, i18n.locale, { maximumFractionDigits: 2 })

export function formatTemperature(value, short = false) {
  const unit = this.$store.getters.units
  if (unit === units.METRIC) {
    return `${formatNumber(value)}${short ? '°' : ' °C'}`
  }
  const fahrenheit = value * 1.8 + 32
  return `${formatNumber(fahrenheit)}${short ? '°' : ' °F'}`
}

/**
 * Convert pressure from Pa into hPa / inHg
 * @param value {number}
 * @param showUnit {boolean}
 * @returns {string}
 */
export function formatPressure(value, showUnit = true) {
  const unit = this.$store.getters.units
  if (unit === units.METRIC) {
    const hasUnit = showUnit ? 'hPa' : ''
    const hpa = number((value / 100), this.$i18n.locale, { maximumFractionDigits: 0 })
    return `${hpa}${hasUnit}`
  }
  const hasUnit = showUnit ? 'inHg' : ''
  const inhg = formatNumber(value * 0.0002953)
  return `${inhg}${hasUnit}`
}
