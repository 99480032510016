/**
 * Object representing various features for a running event.
 * @typedef {Object} RunningEventFeatures
 * @property {boolean} boostRunner - Indicates whether boost runner feature is enabled.
 * @property {boolean} countryLeaderboard - Indicates whether country leaderboard feature is enabled.
 * @property {boolean} countryLeaderboardNumbers - Indicates whether displaying numbers on the country
 * leaderboard is enabled.
 * @property {boolean} fanCheering - Indicates whether fan cheering feature is enabled.
 * @property {boolean} liveLeaderboard - Indicates whether live leaderboard feature is enabled.
 * @property {boolean} liveLeaderboardDistances - Indicates whether displaying distances on the live leaderboard
 * is enabled.
 * @property {boolean} liveLeaderboardStates - Indicates whether displaying states on the live leaderboard is enabled.
 * @property {boolean} raceFinished - Indicates whether the race has finished.
 * @property {boolean} raceProgress - Indicates whether showing race progress is enabled.
 * @property {boolean} registerNowTeaser - Indicates whether race start teaser is enabled.
 * @property {boolean} runnersInOut - Indicates whether showing the number of runners in and out is enabled.
 * @property {boolean} showResults - Indicates whether showing results is enabled.
 * @property {boolean} showPreregister - Indicated whether showing the global bottom alert with preregister link
 */

/**
 * Global statistics.
 * @typedef {Object} Globals
 * @property {Object} in - Statistics for entries coming in.
 * @property {number} in.m - Number of male entries.
 * @property {number} in.w - Number of female entries.
 * @property {number} in.total - Total number of entries coming in.
 * @property {Object} leader - Statistics for the current leaders.
 * @property {number} leader.m - Number of male leaders.
 * @property {number} leader.w - Number of female leaders.
 * @property {Object} out - Statistics for entries going out.
 * @property {number} out.m - Number of male entries going out.
 * @property {number} out.w - Number of female entries going out.
 * @property {number} out.total - Total number of entries going out.
 * @property {Object} total - Total statistics.
 * @property {number} total.m - Total number of male entries.
 * @property {number} total.w - Total number of female entries.
 * @property {number} total.total - Grand total of all entries.
 */

/**
 * Object representing the leaders in a certain context, categorized by gender.
 * @typedef {Object} Leaders
 * @property {Object[]} m - Array containing male leaders.
 * @property {string} m[].id - The unique identifier of the male leader.
 * @property {string} m[].bib - The bib number of the male leader.
 * @property {number} m[].locationId - The location ID of the male leader.
 * @property {string} m[].firstname - The first name of the male leader.
 * @property {string} m[].lastname - The last name of the male leader.
 * @property {Object[]} w - Array containing male leaders.
 * @property {string} w[].id - The unique identifier of the male leader.
 * @property {string} w[].bib - The bib number of the male leader.
 * @property {number} w[].locationId - The location ID of the male leader.
 * @property {string} w[].firstname - The first name of the male leader.
 * @property {string} w[].lastname - The last name of the male leader.
 */

/**
 * Information about a specific location.
 * @typedef {Object} LocationInfo
 * @property {string} id - The unique identifier of the location.
 * @property {string} webcode - The webcode of the location.
 * @property {string} keywords - Keywords associated with the location.
 * @property {string} title - The title of the location.
 * @property {number} lat - The latitude of the location.
 * @property {number} lng - The longitude of the location.
 * @property {string} image - The image URL associated with the location.
 * @property {string} type - The type of location.
 * @property {number} time_offset - Time offset for the location.
 * @property {string} country - The country code of the location.
 * @property {string} iso2 - The ISO2 country code of the location.
 * @property {string} status - The status of the location.
 * @property {?boolean} isGlobal - Indicates if the location is global.
 * @property {number} characteristics - Characteristics of the location.
 * @property {number} timeOfDay - Time of day for the location.
 * @property {?number} elevation - Elevation of the location.
 * @property {?string} leading_man - Leading man in the location.
 * @property {?number} leading_man_speed - Speed of the leading man.
 * @property {?number} leading_man_distance - Distance covered by the leading man.
 * @property {?string} leading_woman - Leading woman in the location.
 * @property {?number} leading_woman_speed - Speed of the leading woman.
 * @property {?number} leading_woman_distance - Distance covered by the leading woman.
 * @property {?number} carSpeed - Speed of the car in the location.
 * @property {?number} carDistance - Distance covered by the car in the location.
 * @property {?string} weather - Weather information for the location.
 * @property {string} sunset - Sunset time for the location.
 * @property {string} sunrise - Sunrise time for the location.
 * @property {?string} link - Link associated with the location.
 * @property {Object} runners - Object containing information about runners in the location.
 * @property {Object} runners.in - Information about runners entering the location.
 * @property {number} runners.in.m - Number of male runners entering the location.
 * @property {number} runners.in.w - Number of female runners entering the location.
 * @property {number} runners.in.total - Total number of runners entering the location.
 * @property {Object} runners.out - Information about runners leaving the location.
 * @property {number} runners.out.m - Number of male runners leaving the location.
 * @property {number} runners.out.w - Number of female runners leaving the location.
 * @property {number} runners.out.total - Total number of runners leaving the location.
 * @property {Object} runners.total - Total information about runners in the location.
 * @property {number} runners.total.m - Total number of male runners in the location.
 * @property {number} runners.total.w - Total number of female runners in the location.
 * @property {number} runners.total.total - Grand total of all runners in the location.
 */

/**
 * @return {{
 *   features: RunningEventFeatures,
 *   globalDistance: number,
 *   globalLeaderDistance: number,
 *   globalSpeed: 34,
 *   globalTime: number,
 *   globals: Globals,
 *   leaders: Leaders,
 *   locations: LocationInfo,
 *   refresh: string,
 *   start: number,
 *   tstamp: number
 * } | null}
 */
export default async function fetchConfig() {
  try {
    const response = await fetch(`${process.env.BASE_URL}${process.env.VUE_APP_JSON_URL}lx.json`)
    return await response.json()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return null
  }
}
