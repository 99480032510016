import {
  SET_EVENT_DATE,
  SET_CATCHER_CAR_SPEED,
  SET_CATCHER_CAR_DISTANCE,
  SET_LEADERS,
  SET_IN,
  SET_OUT
} from '../mutation-types'

export default {
  state() {
    return {
      startTime: new Date(process.env.VUE_APP_EVENT_DATE),
      car: {
        distance: 0,
        speed: 0
      },
      out: {
        m: 0,
        w: 0,
        total: 0
      },
      in: {
        m: 0,
        w: 0,
        total: 0
      },
      leaders: {
        m: [],
        w: []
      }
    }
  },
  mutations: {
    [SET_EVENT_DATE](state, payload) {
      const date = new Date(payload)
      if (date) {
        state.startTime = date
      }
    },
    [SET_CATCHER_CAR_SPEED](state, payload) {
      state.car.speed = payload
    },
    [SET_CATCHER_CAR_DISTANCE](state, payload) {
      state.car.distance = payload
    },
    [SET_LEADERS](state, { m, w }) {
      state.leaders = { m, w }
    },
    [SET_IN](state, { m, w, total }) {
      state.in = { m, w, total }
    },
    [SET_OUT](state, { m, w, total }) {
      state.out = { m, w, total }
    }
  },
  actions: {
    /**
     * Trigger updates to startTime so all getters get updates
     *  - this allows to automatically switch app modes
     * @param state
     * @returns {number | Object}
     */
    initEventCounter({ state, commit }) {
      return setInterval(() => {
        commit(SET_EVENT_DATE, state.startTime)
      }, process.env.NODE_ENV === 'production' ? 2e3 : 16e3)
    }
  },
  getters: {
    startTime: state => state.startTime,
    localStartTime: state => (offset = 0) => new Date(state.startTime.getTime() + (offset * 60000)),
    catcherCarSpeed: state => state.car.speed,
    catcherCarDistance: state => state.car.distance,
    runnersIn: state => state.in.total || 0,
    runnersOut: state => state.out.total || 0,
    runnersTotal: state => state.in.total + state.out.total,
    globalLeader: (state, { participantById, leaders }) => ([
      participantById(leaders.m[0]),
      participantById(leaders.w[0])
    ].filter(item => !!item)),
    leaders: ({ leaders: { m, w } }) => ({
      m: m.filter(participantId => participantId && participantId.indexOf('unknownRunner') < 0),
      w: w.filter(participantId => participantId && participantId.indexOf('unknownRunner') < 0)
    }),
    leadersMale: (state, { leaders, participantById }) => leaders.m.map(participantById),
    leadersFemale: (state, { leaders, participantById }) => leaders.w.map(participantById)
  }
}
