<template>
  <aside>
    <h2
      v-if="globalPartners.length"
      class="h3 text-center my-4"
      v-text="$t('sponsors.header')"
    />
    <section class="global-grid">
      <div
        v-for="globalPartner in globalPartners"
        :key="globalPartner.id"
        class="mb-3 sponsor-card row mx-auto"
      >
        <a
          v-track-click="['Global Partner', globalPartner.name]"
          :href="globalPartner.url"
          class="d-flex flex-row overflow-hidden"
          target="_blank"
        >
          <img
            v-if="globalPartner.fullTeaser"
            :alt="globalPartner.name"
            v-bind="fullTeaser(globalPartner.fullTeaser)"
            class="img-fluid rounded-lg"
            loading="lazy"
          >
          <template v-else>
            <div
              v-if="globalPartner.logo"
              class="d-flex align-items-center align-content-center"
              :class="{'w-50':globalPartner.teaser, 'px-4':!globalPartner.teaser}"
            >
              <img
                :alt="globalPartner.name"
                v-bind="src(globalPartner.logo.path)"
              >
            </div>
            <div
              v-if="globalPartner.teaser"
              :class="$style.img"
              class="w-50"
            >
              <img
                v-bind="teaser(globalPartner.teaser)"
                :alt="globalPartner.name"
                class="img-fluid"
                loading="lazy"
              >
            </div>
          </template>
        </a>
      </div>
    </section>
    <section
      v-for="{ type, sponsors } in groups"
      :key="`sponsors${type}`"
    >
      <h5 class="text-center my-3 small text-dark text-condensed font-weight-bold text-uppercase divider">
        <span class="text-nowrap px-2">{{ $t(`sponsors.types.${type}`) }}</span>
      </h5>
      <div
        class="row py-2 justify-content-center d-flex"
      >
        <div
          v-for="{ id, title, url, image, imagePath } in sponsors"
          :key="`sponsor${id}`"
          class="col-4 mx-2 mb-3 sponsor-card"
        >
          <a
            v-track-click="['Sponsor', title]"
            v-bind="{ title, href: url }"
            target="_blank"
            class="text-center d-block my-1 p-3"
            rel="noopener"
          >
            <img
              v-if="image"
              :alt="title"
              v-bind="src(imagePath, image)"
              :title="title"
            >
          </a>
        </div>
      </div>
    </section>
  </aside>
</template>

<script>
import { globals } from '@/util/globals'

export default {
  name: 'Sponsors',
  computed: {
    globalPartners() {
      return globals.globalPartners || []
    },
    groups() {
      return globals.sponsors
    }
  },
  methods: {
    fullTeaser(imagePath) {
      return {
        src: `/uploads/fit/380/190/${imagePath}`,
        width: 380,
        height: 190
      }
    },
    teaser(imagePath) {
      return {
        src: `/uploads/fit/512/512/${imagePath}`,
        width: 512,
        height: 512
      }
    },
    src(imagePath, image) {
      return {
        class: 'img-fluid w-100',
        src: `/uploads/original/${imagePath || `/sponsors/${image}`}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.global-grid {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.sponsor-card {
  background-color: white;
  border-radius:8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.img > img {
  object-fit: cover;
}
</style>
