<template>
  <div
    v-if="hasPartners"
    class="text-center mr-lg-3"
  >
    <div
      v-t="'sponsors.types.0'"
      :class="['text-condensed text-uppercase text-truncate', $style.title]"
    />
    <AnimateBetweenElements
      :class="$style.sponsor"
      :interval="6e3"
    >
      <img
        v-for="img in imgs"
        v-bind="img"
        :key="img.title"
      >
    </AnimateBetweenElements>
  </div>
</template>

<script>
import AnimateBetweenElements from '@/components/effects/AnimateBetweenElements.vue'
import { globals } from '@/util/globals'

export default {
  name: 'GlobalSponsor',
  components: { AnimateBetweenElements },
  computed: {
    hasPartners() {
      return !!globals.globalPartners
    },
    imgs() {
      return globals.globalPartners.map(partner => ({
        height: 48,
        title: this.$t('sponsors.types.0'),
        alt: partner.name,
        src: `/uploads/resize/0/96/${partner.logo.path}`
      }))
    }
  }
}
</script>

<style lang="scss" module>
  .title {
    color: theme-color('gray');
    font-size: 0.625rem;
    margin-bottom:-2px;
  }

  .sponsor {
    width: 5rem;
    height: 3rem;
    margin-bottom: -0.5rem;
    text-align: center;

    > img {
      margin-left: -.5rem;
    }
  }
</style>
