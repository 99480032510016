import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'RunnerFemale',
  functional: true,
  render: function (h, ctx) {
    return h(
      "svg",
      _mergeJSXProps([{
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
      }, ctx.data]),
      [h("path", {
        attrs: { d: "M19.18 21.99c-.46-.15-.62-.58-.7-.03-.02.12-.01.19-.04.26-1.36-3.63-.11-6.4-3.6-10.45.11-.97.57-2.35.81-3.02 2 2 1.97-.02 3.27-1.42.28-.28.62-.8.53-1.18-.08-.36-.47-.53-.53-.56-.16-.07-.35.13-.45.26-.4 0-.33.57-.21.93-.52.9-.84.74-1.49.99-.56-1.5-.82-1.98-.82-1.98.06-.9-.47-1.38-.99-1.63l.07-.67c.57.27.68.14.78.14.15-.09.14-.35.14-.72.09.01.18-.03.22-.11.02-.04.01-.08-.04-.23-.12-.41-.14-.32-.05-.7.56-1.95-2.05-2.43-2.82-1.08-.11-.11-.24-.08-.31.02-.36-.06-1.57-.18-1.77.85-.24 1.21-.31 1.5-.96 1.75 0 0 .53.08.91-.54-.18.87-1.24 1.15-1.24 1.15 1.06.5 2.3 0 2.64-.8.35-.8-.07-1.12-.01-1.62.02-.17.13-.42.33-.55 0 .18.04.42.21.46-.03.72.38 1.28.59 1.53-.01.43-.09.69-.16.83-.23.07-.61.22-.93.5-1.33.48-2.3 1.18-3.24 2.12-.35.35 0 .58 0 .58.77.62 1.88 1.16 3.12 1.41.26 2.56-1.23 1.64-1.12 4.43 0 0-.64 1.62-.96 2.96-.01 0-.02.01-.03.01-1.25.49-1.83.24-3.01.95-.44.26-1.15.55-2.34.69-.47-.16-1.35-.65-1.49-.35-.43.85-.51 3.59-.18 3.77.19.11.37.01.45-.2a.27.27 0 0 0 .03-.08c.09-.32.26-.98.65-1.15.6-.22.53-.39.33-.59 6.67-2.03 6.23.35 8.65-4.21l1.7 1.4s.7.52.66.96c-.25 2.25.92 2.04 1.53 5.68-.11.64.07.96.29 1.21.17.17 3.15-1.18 3.3-1.76 0-.89-.87.06-1.72-.21zm-8.1-15.33c.36-.09.7-.16.98-.21.06.33.13.65.2.97-.63-.36-1.18-.76-1.18-.76z" }
      })]
    );
  }
};