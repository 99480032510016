<template>
  <media-object
    v-bind="itemProps"
    :margin="margin"
  >
    <div class="text-condensed font-weight-bold small mt-1 d-flex align-items-center">
      <flag
        :iso="value.nationality"
        :squared="false"
        class="flag-sm flag-rectangular mr-2"
      />
      <span class="text-condensed font-weight-bold">{{ getIoc(value.nationality) }}</span>
      <animated-number
        :value="(value.distance || 0) / 1000"
        :decimals="3"
        class="ml-3"
      >
        <template slot-scope="{ value }">{{ $formatDistance(value.slice(0, -2)) }}</template>
      </animated-number>
    </div>

    <template slot="after">
      <div class="position-relative">
        <StatusBadge
          v-if="hasStatusBadge(value.status)"
          :value="value.status"
        />
      </div>
    </template>
  </media-object>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import { AUDIO_STICKER, DONATE_DISTANCE } from '@/constants/searchModes'
import { mapGetters } from 'vuex'
import getIoc from '@/util/ioc'
import {
  status,
  colors,
  icon,
  athleteTypes
} from '@/constants/participant'
import { icons } from '@/constants/location'
import mediaObjectMixin from '@/mixins/mediaObject'
import hasStatusBadge from '@/util/hasStatusBadge'

export default {
  name: 'ParticipantListItem',
  components: {
    StatusBadge
  },
  status,
  colors,
  icon,
  locationIcons: icons,
  mixins: [mediaObjectMixin],
  computed: {
    ...mapGetters([
      'locationById'
    ]),
    icon() {
      const { gender } = this.value
      if (gender === 'f') {
        return 'runner-female'
      }

      return 'runner-male'
    },
    location() {
      return this.locationById(this.value.locationId)
    },
    itemProps() {
      const participant = this.value

      const isAppRunner = participant.athleteType === athleteTypes.APP
      return {
        key: participant.id,
        title: participant.fullname,
        image: participant.avatar,
        url: {
          ...participant.url,
          query: {
            ...participant.url.query,
            donateDistance: (this.$route.query.search === DONATE_DISTANCE) || undefined,
            participantId: (this.$route.query.search === AUDIO_STICKER && isAppRunner && participant.id) || undefined
          }
        }
      }
    }
  },
  methods: {
    getIoc,
    hasStatusBadge
  }
}
</script>
