/* eslint-disable no-param-reassign */
import locationImage from '@/assets/avatar/location.gif'
import teamAvatar from '@/assets/avatar/team.gif'
import transformParticipant from '@/worker/transformer/transformParticipant'

export default function transformTeam({
  avatar = teamAvatar,
  image = locationImage,
  members = [],
  ...team
}) {
  return {
    avatar,
    image,
    url: { name: 'teams.detail', params: { id: team.id } },
    members: members ? members.map(transformParticipant) : members,
    ...team
  }
}
