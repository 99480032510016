import { NORMAL } from '@/constants/searchModes'
import { shopUrl } from '@/constants/urls'
import {
  PRE, LIVE, POST
} from '@/constants/modes'
import store from '@/store'

const defaultLocale = (navigator.language || navigator.userLanguage).substr(0, 2)
function routeWithLocale(to, from) {
  return {
    ...to,
    params: {
      lang: from.params.lang || defaultLocale,
      ...to.params
    }
  }
}

export default Object.freeze([
  {
    path: '/:lang([a-z-]{2,5})',
    name: 'home',
    redirect(to) {
      return routeWithLocale({ name: store.getters.mode === LIVE ? 'follow' : 'participate' }, to)
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/participate',
    name: 'participate',
    meta: {
      icon: 'staticglobe',
      menu: true,
      modes: [PRE, LIVE, POST]
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "Participate" */ '@/views/ParticipateView.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/follow',
    name: 'follow',
    meta: {
      icon: 'follow',
      menu: true,
      modes: [PRE, LIVE]
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "WelcomeTips" */ '@/views/FollowView.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/results',
    name: 'results',
    meta: {
      icon: 'medal',
      menu: true,
      modes: [POST]
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "Participate" */ '@/views/ResultsView.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/pinboard',
    name: 'pinboard',
    meta: {
      icon: 'pinboard',
      menu: true
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "Pinboard" */ '@/views/Pinboard.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/support',
    name: 'support',
    meta: {
      icon: 'donate',
      menu: true
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "Support" */ '@/views/Support.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/search',
    name: 'search',
    meta: {
      icon: 'search',
      menu: false
    },
    beforeEnter(to, from, next) {
      next({
        ...from,
        query: {
          ...from.query,
          search: to.query.search || NORMAL
        }
      })
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "Search" */ '@/views/Search.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/gallery/upload',
    name: 'imageUpload',
    meta: {
      menu: false
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "ImageUpload" */ '@/views/ImageUpload.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/gallery/upload%60',
    alias: '/gallery/upload`',
    redirect: { name: 'imageUpload' }
  },
  {
    path: '/:lang([a-z-]{2,5})/shop',
    name: 'shop',
    meta: {
      icon: 'shop',
      menu: true,
      hideSidebar: true,
      // eslint-disable-next-line max-len
      href: shopUrl
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/locations/:id?',
    name: 'locations.detail',
    meta: {
      icon: 'locations'
    },
    props: {
      default: true,
      sidebar: true
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "LocationDetail" */ '@/views/LocationDetail.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/participants/:id',
    name: 'participants.detail',
    props: {
      sidebar: true
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "ParticipantDetail" */ '@/views/ParticipantDetail.vue')
    }
  },
  {
    path: '/:lang([a-z-]{2,5})/teams/:id',
    name: 'teams.detail',
    props: {
      sidebar: true
    },
    components: {
      sidebar: () => import(/* webpackChunkName: "TeamDetail" */ '@/views/TeamDetail.vue')
    }
  },
  {
    path: '/(participate|follow|support|search|pinboard|gallery|locations|participants|teams)/:id?',
    redirect(to) {
      return `/${defaultLocale}${to.path}`
    }
  },
  {
    // will match everything
    path: '*',
    redirect(to) {
      return routeWithLocale({ name: 'home' }, to)
    }
  }
])
