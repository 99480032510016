import { wrap, proxy } from 'comlink'

export default {
  watchWorker: null,
  isBooting: null,
  queuedChannels: [],
  provide() {
    return {
      subscribeParticipant: id => this.subscribe(`participants/${id}`, true),
      subscribeTeam: id => this.subscribe(`teams/${id}`, true)
    }
  },
  computed: {
    watchedChannels() {
      return this.$store.state.pinboard.map(item => item.split(':').join('/'))
    }
  },
  watch: {
    watchedChannels: 'subscribeWatched'
  },
  beforeDestroy() {
    const worker = this.$options.watchWorker
    if (worker) {
      try {
        worker.terminate()
      } catch (e) {
        // eslint-disable-next-line
        console.error('watchProvider:terminateWorker', e)
      }
    }
  },
  mounted() {
    this.subscribeWatched(this.watchedChannels)
  },
  methods: {
    async getWatcherWorker() {
      const Watcher = wrap(new Worker(new URL('@/worker/watcher.worker', import.meta.url)))
      const watcher = await new Watcher()

      const { commit } = this.$store
      watcher.on(proxy(commit))
      this.$options.watchWorker = watcher

      this.$options.queuedChannels.forEach(([channel, individual]) => {
        watcher.subscribe(channel, individual)
      })
      return watcher
    },
    async subscribe(channel, individual = false) {
      try {
        const worker = this.$options.watchWorker
        if (worker) {
          worker.subscribe(channel, individual)
          return () => worker.unsubscribe(channel)
        }

        this.$options.queuedChannels.push([channel, individual])
        if (!this.$options.isBooting) {
          this.$options.isBooting = this.getWatcherWorker()
        }

        return new Promise((resolve) => {
          this.$options.isBooting
            .then((w) => {
              resolve(() => w.unsubscribe(channel))
            })
        })
      } catch (e) {
        return () => true
      }
    },
    subscribeWatched(channels) {
      channels.forEach((channel) => {
        if (!channel.match(/^location\//)) {
          this.subscribe(channel)
        }
      })
    }
  }
}
