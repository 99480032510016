import { SET_POINTS } from '../mutation-types'

export default {
  state() {
    return []
  },
  mutations: {
    [SET_POINTS](state, payload) {
      state.splice(0, state.length)

      // from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/apply
      const QUANTUM = 32768
      for (let i = 0, len = payload.length; i < len; i += QUANTUM) {
        Array.prototype.push.apply(state, payload.slice(i, Math.min(i + QUANTUM, len)))
      }
    }
  },
  getters: {
    points: state => state
  }
}
