<template>
  <label
    :class="[$style.sticker, playing && $style.playing, 'rounded-circle m-2']"
    :for="name + value"
    @click="play"
  >
    <img
      :src="require(`@/assets/audio-sticker/${value}.svg`)"
      :alt="value"
      width="112"
      height="112"
      class="rounded-circle"
    >
    <input
      :id="name + value"
      type="radio"
      :name="name"
      :class="$style.input"
      :checked="checked === value"
      :required="required"
      @input="$emit('input', value)"
    >
    <span :class="[$style.indicator, 'rounded-circle']"/>
  </label>
</template>

<script>
let audio

export default {
  name: 'AudioStickerRadioButton',
  model: {
    prop: 'checked'
  },
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    checked: {
      type: [Boolean, String],
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      playing: false
    }
  },
  beforeDestroy() {
    if (audio) {
      audio.pause()
      audio = null
    }
  },
  mounted() {
    if (!audio) {
      audio = new Audio()
    }

    audio.addEventListener('loadstart', this.updatePlayState)
    audio.addEventListener('ended', this.updatePlayState)
    audio.addEventListener('pause', this.updatePlayState)
    this.$once('hook:beforeDestroy', () => {
      if (audio) {
        audio.removeEventListener('loadstart', this.updatePlayState)
        audio.removeEventListener('ended', this.updatePlayState)
        audio.removeEventListener('pause', this.updatePlayState)
      }
    })
  },
  methods: {
    play() {
      // TODO: live-stream pause/mute
      if (this.playing) {
        audio.pause()
        return
      }
      if (audio.src !== this.src) {
        audio.src = this.src
        audio.play()
      } else {
        audio.play()
      }
    },
    updatePlayState(e) {
      this.playing = e.target.src === this.src && !e.target.paused
    }
  }
}
</script>

<style lang="scss" module>
.sticker {
  position: relative;
  border: .5rem solid $white;
  cursor: pointer;

  transition: all .2s ease-in;
  box-shadow: 0 .0625rem .1875rem rgba(0, 0, 0, 0.06), 0 .5rem 1.25rem rgba(0, 0, 0, 0.08);
  will-change: transform, box-shadow; // force rendering on GPU to prevent flickering!

  &:hover, &:focus, &:focus-within {
    transform: scale(1.0075);
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.04), 0 1rem 2rem rgba(0, 0, 0, 0.12);
    transition-timing-function: ease-out;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(theme-color('primary'), 0.5);
    border-radius: inherit;
    overflow: inherit;
    @include fill-background-image('@/assets/waveform.svg', $white, 'stroke');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 3rem 3rem;
    z-index: 1;
    opacity: 0;
    transition: opacity .2s ease-in;
  }

  &.playing {
    &::after {
      opacity: 1;
      transition-timing-function: ease-out;
    }
  }
}

.input {
  position: absolute;
  visibility: hidden;

  &:checked ~ .indicator {
    transform: none;
    transition-timing-function: ease-out;
  }
}

.indicator {
  position: absolute;
  top: -.5rem;
  right: -.5rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: theme-color('secondary');
  @include fill-background-image('@/assets/icons/checkmark.svg', #fff, 'stroke');
  background-size: 1.25rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: transform .2s ease-in;
  transform: scale(0);
  transform-origin: 50% 50%;
  z-index: 3
}
</style>
