<template>
  <div id="app">
    <nav-bar
      :q.sync="q"
      :class="[showSearch && 'shadow']"
      @change="controlMenu"
      @toggleSearch="showSearch = $event"
    />
    <side-nav-bar
      :items="routes"
      :value="showMoreDropDown"
      @change="controlMenu"
    >
      <div
        slot="after"
        class="mt-auto w-100 d-none d-lg-flex flex-column align-items-center"
      >
        <language-select
          v-model="showLangMenu"
          compact
          class="side-nav-bar-item"
        />
        <hr>
        <div class="side-nav-bar-item px-2">
          <distance-unit class="w-100"/>
        </div>
      </div>
    </side-nav-bar>

    <transition name="fade">
      <SearchOverlay
        v-if="showSearch"
        :q.sync="q"
      />
    </transition>

    <transition name="fade">
      <AudioStickerOverlay
        v-if="showAudioSticker"
        v-model="showAudioSticker"
      />
    </transition>

    <main
      class="site-content"
      :class="{ 'live-stream-docked': dockedPlayer, 'scroll-content': $route.meta.hideSidebar }"
    >
      <div
        v-if="!$route.meta.hideSidebar"
        id="sidebar"
        class="site-content-sidebar position-relative"
      >
        <transition
          :name="transition.side"
          appear
        >
          <keep-alive exclude="Blog">
            <router-view name="sidebar"/>
          </keep-alive>
        </transition>

        <Sponsors class="container my-3"/>
      </div>
      <transition
        name="fade"
      >
        <router-view
          v-if="$route.meta.hideSidebar"
        />
        <main-view
          v-else
          :transition="transition.main"
          class="site-content-main position-relative h-100"
        >
          <div class="w-100 mb-3 mb-lg-4 pt-lg-3 mt-lg-auto">
            <LiveStreams/>
            <NavTabs :items="routes"/>
          </div>
        </main-view>
      </transition>
      <BaseFooter class="d-flex d-md-none order-last"/>
    </main>

    <donate-modal
      v-model="showDonateModal"
      :url="donateUrl"
    />
    <ModalBackdrop
      :value="showOffCanvasMenu"
      @click.native="controlMenu(false)"
    />
    <off-canvas-nav
      v-model="showOffCanvasMenu"
      :items="routes"
    >
      <template #before>
        <figure class="card shadow-none mb-0">
          <img
            srcset="@/assets/SI201905060808_news.jpg 1x, @/assets/SI201905060808_news@2x.jpg 2x"
            src="@/assets/SI201905060808_news.jpg"
            :alt="$t('sideNav.supportYourFavorite')"
            class="img-fluid w-100"
            width="343"
            height="228"
          >
          <div
            class="card-img-overlay text-primary text-right w-75 ml-auto"
            style="top: auto;"
          >
            <h2
              v-t="'sideNav.supportYourFavorite'"
              class="white-space-pre h3"
            />
            <router-link
              :to="{ ...$route, query: { ...$route.query, search: $options.searchModes.DONATE_DISTANCE } }"
              class="btn btn-secondary"
              @click="showOffCanvasMenu = false"
            >
              <icon
                src="@/assets/icons/donate.svg"
                class="mr-1"
                sm
              />
              {{ $t('donate.perKm') }}
            </router-link>
          </div>
        </figure>
      </template>
    </off-canvas-nav>
    <LanguageOverlay v-model="showLanguageOverlay"/>

    <GlobalAlerts/>
    <RegisterBottomTeaser/>

    <portal-target name="after"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import pollsUpdates from '@/mixins/pollsUpdates'
import watchProvider from '@/mixins/watchProvider'
import mediaQueries from '@/mixins/mediaQueries'
import LanguageOverlay from '@/components/LanguageOverlay.vue'
import NavTabs from '@/components/layout/NavTabs.vue'
import GlobalAlerts from '@/components/GlobalAlerts.vue'
import OffCanvasNav from '@/components/layout/OffCanvasNav.vue'
import SearchOverlay from '@/components/SearchOverlay.vue'
import AudioStickerOverlay from '@/components/AudioStickerOverlay.vue'
import Sponsors from '@/components/Sponsors.vue'
import DonateModal from '@/components/DonateModal.vue'
import * as searchModes from '@/constants/searchModes'
import { mode2Id } from '@/constants/modes'
import BaseFooter from '@/components/base/BaseFooter.vue'
import RegisterBottomTeaser from '@/components/teasers/RegisterBottomTeaser.vue'
import NavBar from './components/layout/NavBar.vue'
import SideNavBar from './components/layout/SideNavBar.vue'
import MainView from './views/main/MainView.vue'
import routes from './router/routes'
import DistanceUnit from './components/DistanceUnit.vue'
import LanguageSelect from './components/LanguageSelect.vue'
import LiveStreams from './components/LiveStreams.vue'
import { DOCKED } from './constants/liveStream'
import { loadLanguageAsync } from './i18n'

export default {
  name: 'LiveExperience',
  searchModes,
  components: {
    RegisterBottomTeaser,
    BaseFooter,
    AudioStickerOverlay,
    Sponsors,
    LanguageOverlay,
    NavBar,
    SideNavBar,
    NavTabs,
    OffCanvasNav,
    MainView,
    DistanceUnit,
    LanguageSelect,
    LiveStreams,
    SearchOverlay,
    GlobalAlerts,
    DonateModal
  },
  mixins: [pollsUpdates, watchProvider, mediaQueries({
    xs: 0,
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  })],
  provide() {
    return {
      updateMetaTags: (tags) => {
        this.$nextTick(() => {
          this.updateMetaTags(tags)
        })
      }
    }
  },
  data() {
    return {
      routes,
      showLangMenu: false,
      showMoreDropDown: false,
      showOffCanvasMenu: false,
      openedOffCanvasMenu: false,
      q: '',
      transition: {
        side: 'fade',
        main: 'fade'
      }
    }
  },
  computed: {
    ...mapGetters([
      'donateUrl',
      'mode'
    ]),
    showDonateModal: {
      get() {
        return this.$store.getters.showDonateModal
      },
      set(value) {
        this.$store.dispatch('hideDonate', value)
      }
    },
    showLanguageOverlay: {
      get() {
        return this.$route.hash === '#language'
      },
      set(value) {
        this.$router.push({
          ...this.$route,
          hash: value ? '#language' : undefined
        })
      }
    },
    dockedPlayer() {
      return this.$store.state.liveStream.position === DOCKED
    },
    showSearch: {
      get() {
        return this.$route.query.search
      },
      set(value) {
        // prevent changing search mode!
        if (value && this.$route.query.search) {
          return
        }

        const search = value === true ? 'search' : value || undefined
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            search
          }
        })
      }
    },
    showAudioSticker: {
      get() {
        return this.$route.query.audioSticker === true || this.$route.query.audioSticker === 'true'
      },
      set(audioSticker) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            audioSticker: audioSticker || undefined,
            participantId: audioSticker ? this.$route.query.participantId : undefined
          }
        })
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.params.lang': async function (lang) {
      // make sure language files are loaded
      await loadLanguageAsync(lang || this.$i18n.locale)
      this.updateMetaTags()
    },
    mode() {
      this.updateMetaTags()
    },
    $route(route, oldRoute) {
      if (route.name === oldRoute.name && route.hash !== oldRoute.hash) {
        // just open language overlay
        return
      }

      this.showMoreDropDown = false
      this.showOffCanvasMenu = false

      this.updateMetaTags()

      this.$gtmEvent({
        event: 'gaNavEvent',
        eventCategory: 'Navigation',
        eventAction: 'Main Navigation',
        eventLabel: route.fullPath
      })
    },
    showDonateModal: 'toggleBodyScrolling',
    showSearch: 'toggleBodyScrolling'
  },
  mounted() {
    this.updateMetaTags()
    this.initBlog()
    this.$options.eventCounter = this.initEventCounter()
  },
  beforeDestroy() {
    if (this.$options.eventCounter) {
      clearInterval(this.$options.eventCounter)
    }
  },
  methods: {
    ...mapActions([
      'initBlog',
      'initEventCounter',
      'donate'
    ]),
    updateMetaTags(tags = {}) {
      // eslint-disable-next-line prefer-destructuring
      let title = tags.title

      if (!title && /detail$/.test(this.$route.name)) {
        // stop if we are on a detail page and still have no title
        // to allow detail page to properly set title
        return
      }

      if (!title && this.$route.meta.menu) {
        title = this.$route.name === 'global'
          ? this.$tc(`menu.${this.$route.name}`, mode2Id(this.mode))
          : this.$t(`menu.${this.$route.name}`)
      }

      document.title = [
        title,
        process.env.VUE_APP_TITLE
      ]
        .filter(item => !!item)
        .join(' - ')
    },
    controlMenu(value) {
      this.openedOffCanvasMenu = true

      this.showMoreDropDown = value && this.media.lg
      this.showOffCanvasMenu = value && !this.showMoreDropDown
    },
    toggleBodyScrolling(value) {
      if (typeof document !== 'undefined') {
        document.body.classList.toggle('modal-open', value)
      }
    }
  }
}
</script>

<style lang="scss" src="./scss/app.scss"></style>
