import gtmEvent from '@/util/gtmEvent'

import {
  PIN,
  SET_MODAL_OPEN,
  SET_PLAYER,
  UNPIN
} from '@/store/mutation-types'

export default (store) => {
  store.subscribe((mutation, state) => {
    if (mutation.type === SET_MODAL_OPEN && mutation.payload) {
      gtmEvent({
        event: 'gaBuyEvent',
        eventCategory: 'Buy Button',
        eventAction: 'Donate',
        eventLabel: state.donateModal.url
      })
    }

    if (mutation.type === `radio/${SET_PLAYER}`) {
      gtmEvent({
        event: 'gaIntEvent',
        eventCategory: 'Interactions',
        eventAction: mutation.payload === false ? 'Play Button' : 'Stop Button',
        eventLabel: undefined
      })
    }

    if (mutation.type === PIN) {
      const { type, id } = mutation.payload
      gtmEvent({
        event: 'gaIntEvent',
        eventCategory: 'Interactions',
        eventAction: 'Pin Button',
        eventLabel: `${type}s/${id}`
      })
    }

    if (mutation.type === UNPIN) {
      const { type, id } = mutation.payload
      gtmEvent({
        event: 'gaIntEvent',
        eventCategory: 'Interactions',
        eventAction: 'Unpin Button',
        eventLabel: `${type}s/${id}`
      })
    }
  })
}
