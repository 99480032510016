<template>
  <transition name="slide-in-right">
    <aside
      v-show="value"
      class="off-canvas-nav"
    >
      <div :class="[$style.header, 'bg-secondary']">
        <button
          :aria-label="$t('close')"
          type="button"
          class="close"
          @click="close"
        >
          <icon name="close"/>
        </button>
      </div>

      <div class="my-4 mx-3">
        <div
          v-if="$slots.before"
          class="mb-3"
        >
          <slot name="before"/>
        </div>

        <nav class="nav flex-column flex-nowrap w-100 text-uppercase font-weight-bold font-lg">
          <component
            :is="is"
            v-for="{ is, name, url, target, icon, disabled } in mainItems"
            :key="String(url) || name"
            v-bind="target ? { href: url } : { to: url }"
            :target="target"
            :class="['nav-link', { disabled }]"
            @click.native="trackClick($t(`menu.${name}`))"
            @click="trackClick($t(`menu.${name}`))"
          >
            <icon
              v-if="icon"
              :name="icon"
              class="mb-1 mb-lg-2 mr-2"
            />
            {{ $t(`menu.${name}`) }}
          </component>
          <LanguageButton
            class="nav-link"
            @click.native="trackClick('LanguageButton')"
            @click="trackClick('LanguageButton')"
          />
          <a
            :href="`/${$i18n.locale}`"
            class="text-uppercase nav-link"
            style="text-transform: none"
            @click="setRedirectInhibit"
          >
            <icon
              name="desktop"
              class="mb-1 mb-lg-2 mr-2"
            />
            {{ $t('menu.switchToDefault') }}
          </a>
        </nav>
        <distance-unit
          theme="primary"
          sm
          checkbox
          class="mt-3 text-uppercase"
        />
        <template v-if="$slots.default">
          <hr class="my-4">
          <slot/>
        </template>
      </div>
      <footer class="off-canvas-nav-footer mt-auto text-right pb-3 px-2">
        <SocialSharing class="mb-3"/>
        <div
          v-if="subItems"
          class="small font-weight-bold submenu-items d-flex justify-content-end"
        >
          <component
            :is="is"
            v-for="{ is, name, url, target, icon, disabled } in subItems"
            :key="String(url) || name"
            v-bind="target ? { href: url } : { to: url }"
            :target="target"
            :class="{ disabled }"
            class="nav-link"
          >
            <icon
              v-if="icon"
              :name="icon"
              class="mb-1 mb-lg-2"
            />
            {{ $t(`menu.${name}`) }}
          </component>
        </div>
      </footer>
      <small
        style="font-size: 6px; opacity:.5; position:fixed; bottom:2px; right: calc(2px + env(safe-area-inset-bottom))"
      >{{ $options.version }}</small>
    </aside>
  </transition>
</template>

<script>
import DistanceUnit from '@/components/DistanceUnit.vue'
import LanguageButton from '@/components/LanguageButton.vue'
import LanguageSelect from '@/components/LanguageSelect.vue'
import SocialSharing from '@/components/SocialSharing.vue'
import menuItems from '@/mixins/menuItems'

export default {
  name: 'OffCanvasNav',
  version: process.env.VUE_APP_VERSION,
  components: {
    SocialSharing,
    LanguageButton,
    DistanceUnit,
    LanguageSelect
  },
  mixins: [menuItems],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showLangMenu: false
    }
  },
  computed: {
    menuItems() {
      return this.menu.slice(this.maxItems)
    },
    mainItems() {
      return this.menuItems.filter(item => item.icon)
    },
    subItems() {
      return this.menuItems.filter(item => !item.icon)
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    trackClick(text) {
      this.$gtmEvent({
        event: 'gaEvent',
        eventCategory: 'Navigation',
        eventAction: 'Mobile Off-Canvas Navigation',
        eventLabel: text
      })
      this.$gtmEvent({
        event: 'ga4Event',
        event_name: 'navigation',
        navigation: {
          element_position: 'Mobile Off-Canvas Navigation',
          content_name: text
        }
      })
    },
    setRedirectInhibit() {
      window.sessionStorage.setItem('lxRedirectInhibit', Date.now()
        .toString())
      window.localStorage.setItem('lxRedirectInhibit', Date.now()
        .toString())
    }
  }
}
</script>

<style lang="scss">
.off-canvas-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 24rem;
  max-width: 100vw;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $white;
  z-index: $zindex-modal;
  padding-top: env(safe-area-inset-top);
  padding-bottom: 12px; // height of version

  &-footer {
    flex-shrink: 0;
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1rem; // prevent additional bottom padding
    color: $white;
  }

  .submenu-items {
    .nav-link {
      color: gray('600');
      text-transform: uppercase;

      @include hover-focus() {
        color: theme-color('primary');
      }

      & + .nav-link {
        margin-left: map-get($spacers, 2)
      }
    }
  }

  .language-menu .btn {
    font-weight: $font-weight-base;
    text-transform: none;
  }
}
</style>

<style lang="scss" module>
.header {
  height: 8.5rem;
  flex-shrink: 0;
  $url: 'url("~@/assets/logo-wflwr-monochrome.svg?fill=#{$white}&fill-opacity=0.32")';
  background-image: unquote(str-replace($url, '#', '%23')) !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) calc(100% - 1rem);
  background-size: 146px 67px;
}
</style>
