<script>
import { mapGetters } from 'vuex'
import { getLocaleLink } from '@/util/localization'
import getPreregisterDate from '@/util/getPreregisterDate'
import * as modes from '@/constants/modes'

const alertName = 'preregister-now-alert'

export default {
  name: 'RegisterBottomTeaser',
  modes,
  data() {
    return {
      hasAlert: false,
      preregisterYear: getPreregisterDate().getFullYear()
    }
  },
  computed: {
    ...mapGetters([
      'mode',
      'hasFeature'
    ])
  },
  mounted() {
    this.hasAlert = !localStorage.getItem(alertName)
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    getLocaleLink,
    closeAlert() {
      this.hasAlert = false
      localStorage.setItem(alertName, String(new Date()))
    },
    handleClickOutside(event) {
      const alert = this.$refs?.alert
      if (alert && !alert.contains(event.target)) {
        this.closeAlert()
      }
    }
  }
}
</script>

<template>
  <div
    v-if="hasAlert && [$options.modes.LIVE].includes(mode) && hasFeature('showPreregister')"
    style="height:100vh; width:100vw;z-index:9999"
    class="background-overlay position-fixed top-0 bottom-0"
  >
    <div
      ref="alert"
      class="bg-primary position-fixed bottom-0 left-0 right-0 w-100 text-white position-relative border-top-lg p-5"
    >
      <section class="align-items-center d-flex flex-column text-center">
        <h3
          class="text-white"
          v-text="$t('preregister.header', {year: preregisterYear})"
        />
        <p
          class="subtle"
          v-text="$t('preregister.text')"
        />
        <a
          v-track-click="'Register Bottom Teaser'"
          class="btn btn-secondary btn-sized"
          :href="getLocaleLink('/locations')"
        >
          {{ $t('preregister.btn') }}
        </a>
      </section>

      <button
        class="btn close-btn text-white"
        @click="closeAlert"
      >
        <icon
          name="close"
          class="icon-md"
        />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '/src/scss/bootstrap/_variables.scss';

.btn-sized {
  width: 25%;
  @media(max-width: #{breakpoint-max(md)}) {
    width: 100%;
  }
}

.subtle {
  color: $text-light-subtle;
}

.border-top-lg {
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;

  &:hover {
    color: $secondary !important;
  }
}

.background-overlay{
  background: rgba(0, 0, 0, 0.5);
}
</style>
