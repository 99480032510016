/**
 * get formatted date YYYY:MM:DD
 * @param jsDate {Date|String}
 * @param locale {String}
 * @param options
 * @return {undefined|string}
 */
export default function date(jsDate, locale, options = {}) {
  if (!jsDate) {
    return undefined
  }

  let dateCopy = jsDate

  if (!(dateCopy instanceof Date)) {
    dateCopy = new Date(jsDate)
  }

  return new Intl.DateTimeFormat(locale, ({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...options
  })).format(dateCopy)
}
