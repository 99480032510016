export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    margin: {
      type: [Number, String],
      default: 2
    }
  }
}
