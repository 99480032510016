import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'RunnerMale',
  functional: true,
  render: function (h, ctx) {
    return h(
      "svg",
      _mergeJSXProps([{
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
      }, ctx.data]),
      [h("path", {
        attrs: { d: "M19.74 22.03c-.27.03-1.04-.4-1.16-.33-.12.06-.09.42-.14.52-.63-1.69-.68-3.43-1.02-5.18 0-1.82-1.44-3.95-2.57-5.27.11-.97.57-2.35.81-3.02 2 2 1.98-.03 3.27-1.42.08-.08.23-.23.36-.48.29-.56.27-.95-.36-1.26-.16-.07-.35.13-.45.26-.4 0-.33.57-.21.93-.4.68-.81.75-1.5.99-.56-1.5-.82-1.98-.82-1.98.06-.9-.47-1.38-.99-1.63l.07-.67c.57.27.68.14.78.14.15-.09.14-.35.14-.72.09.01.18-.03.22-.11.02-.04.01-.08-.04-.23-.27-.77.21-.96-.06-1.72a1 1 0 0 0-.26-.39c-1.34-1.3-3.94.39-2.17 2.58-.01.43-.09.69-.16.83-.23.07-.61.22-.93.5-.55.19-4.06 1.96-3.24 2.7.77.62 1.88 1.16 3.12 1.41.25 2.54-1.22 1.65-1.12 4.43 0 0-.64 1.62-.96 2.96-.01 0-.02.01-.03.01-.95.37-1.97.4-2.88.88-.8.42-1.58.67-2.48.77-.47-.16-1.35-.65-1.49-.35-.3.58-.79 3.82.03 3.82.16-.03.23-.19.25-.24a.27.27 0 0 0 .03-.08c.4-1.41.65-.99 1.07-1.39.11-.11.03-.23-.09-.35 2.03-.62 4.08-.89 6.17-1.2.52-.08 1.29-.77 2.48-3.01l1.7 1.4c1.2.94-.12 1.13 1.38 3.89.24.44.55 1.32.79 2.75-.07.41 0 .9.29 1.21.17.17 3.15-1.18 3.3-1.76.22-.77-.92-.21-1.13-.19zM11.07 6.66c.36-.09.7-.16.98-.21.06.33.13.65.2.97-.63-.36-1.18-.76-1.18-.76z" }
      })]
    );
  }
};