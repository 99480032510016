export const START = 'start'
export const RUNNING = 'running'
export const OVER = 'over'
export const CANCELLED = 'cancelled'

export const types = {
  EVENT: 'event-run',
  OAR: 'oar',
  APP: 'app'
}

export const icons = {
  [types.EVENT]: 'cc',
  [types.APP]: 'app',
  [types.OAR]: 'app'
}

export const timeOfDayIcons = {
  1: 'day',
  2: 'night',
  3: 'night-day',
  4: 'day-night'
}

export const characteristicIcons = {
  1: 'lake',
  2: 'city',
  3: 'nature',
  4: 'nature',
  5: 'desert',
  6: 'lake',
  7: 'city'
}
