/* eslint-disable */

class ApiClient {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  get(url) {
    return this._fetch('GET', url)
  }

  post(url, payload) {
    return this._fetch('POST', url, payload)
  }

  async postDirect(url, body) {
    const response = await fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json'
      }),
      body
    })

    return await this.handleResponse(response)
  }

  async _fetch(method = 'GET', url, payload) {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method,
        headers: new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json'
          // 'Accept-Language': i18n.locale
        }),
        body: payload ? JSON.stringify(payload) : undefined
      })

    return await this.handleResponse(response)
  }

  async handleResponse(response) {
    if (response.status >= 400 && response.status < 500) {
      const errorBody = await response.json().catch(() => null);
      const errorMessage = errorBody ? errorBody.error.message : 'Client error';
      const error = new Error(errorMessage);
      error.status = response.status;
      console.error(error)
      return
    }
    if (!response.ok || response.status >= 500) {
      const errorMessage = response.statusText || 'Failed to fetch';
      const error = new Error(errorMessage);
      error.status = response.status;
      throw error;
    }

    if (response.status === 204) {
      return null;
    }

    return response.json();
  }

}

export const api = new ApiClient(process.env.VUE_APP_MAIN_API_URL)

const youtubeApi = new ApiClient('https://www.googleapis.com/youtube/v3/')

export function getYoutubeVideos(playlistId) {
  return youtubeApi.get(`playlistItems?part=snippet&playlistId=${playlistId}&maxResults=6&key=${process.env.VUE_APP_YOUTUBE_API_KEY}`)
    .then(response => response.items)
}

export function getStickers() {
  return api.get('ax/fan/stickers')
    .then(response => response.data)
}

export function postSticker(key, userId) {
  return api.post('ax/fan/stickers', {
    key,
    userId
  })
}

export async function putTeamAudioStickers(userId, file) {
  const context = new AudioContext()
  const formData = new FormData()

  const buffer = await context.decodeAudioData(await file.arrayBuffer())
  formData.set(`file`, file, 'sticker.mp3')
  formData.set(`length`, buffer.duration)

  return api.postDirect(`users/${userId}/audio-stickers`, formData)
}
