import Vue from 'vue'
import qs from 'query-string'
import array from '@/util/array'
import RecordFragment from '@/store/modules/RecordFragment'
import transformTeam from '@/worker/transformer/transformTeam'
import transformParticipant from '@/worker/transformer/transformParticipant'
import { SET_TEAM, PATCH_TEAM, SET_PARTICIPANT } from '../mutation-types'

export default {
  state() {
    return {}
  },
  mutations: {
    [SET_TEAM](state, teams) {
      array(teams).forEach((team) => {
        if (!(team.id in state && (team.constructor === RecordFragment || team.incomplete))) {
          Vue.set(state, team.id, team)
        }
      })
    },
    [PATCH_TEAM](state, teams) {
      array(teams).forEach((team) => {
        Vue.set(state, team.id, team)
        // Vue.set(state, team.id, Object.assign({}, state.teams[team.id], team))
      })
    }
  },
  actions: {
    addTeam({ commit }, team) {
      commit(SET_TEAM, array(team).map(p => new RecordFragment(p)))
    },
    async getTeamById({ commit, state, getters: { participantById } }, id) {
      const team = state[id]

      if (!team || (team && team.incomplete)) {
        const response = await fetch(`${process.env.VUE_APP_LIVE_API_URL}teams/${id}`)
        if (response.ok) {
          const { data: p } = await response.json()
          const transformedTeam = transformTeam(p)

          if (transformTeam.members) {
            commit(SET_PARTICIPANT, transformedTeam.members)
            transformedTeam.members = transformedTeam.members.map(participant => participantById(participant.id))
          }

          commit(SET_TEAM, array(transformedTeam))
          return p
        }
      }

      return team
    },
    async getTeamMembers({ commit }, { id, ...params }) {
      const response = await fetch(`${process.env.VUE_APP_LIVE_API_URL}teams/${id}/members?${qs.stringify(params)}`)

      if (response.ok) {
        const { data, meta } = await response.json()
        const members = data.map(transformParticipant)
        commit(SET_PARTICIPANT, members)
        return { data: members, meta }
      }

      return []
    }
  },
  getters: {
    teamById: state => id => state[id],
    teams: state => Object.values(state)
  }
}
