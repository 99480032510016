import { mapActions } from 'vuex'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    pinned() {
      return this.$store.getters.isPinned(this.type, this.id)
    }
  },
  methods: {
    ...mapActions([
      'pin',
      'unPin'
    ]),
    toggle() {
      this[this.pinned ? 'unPin' : 'pin']({
        type: this.type,
        id: this.id
      })
    }
  }
}
