<script>
export default {
  name: 'AnimatedInteger',
  functional: true,
  render(h, { props, data: { attrs, ...data }, parent }) {
    const locale = parent.$i18n ? parent.$i18n.locale : undefined

    return h('animated-number', {
      ...data,
      props: {
        decimals: 0,
        ...props
      },
      scopedSlots: {
        /* eslint-disable-next-line no-underscore-dangle */
        default: ({ value }) => parent._f('number')(value, locale)
      }
    })
  }
}
</script>
