<template functional>
  <div
    :class="data.staticClass"
    class="loader mx-auto"
  />
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss">
  .loader {
    animation: spin 1.25s linear infinite;
    border: 3px solid gray('300');
    border-top: 3px solid theme-color('primary');
    border-radius: 50%;
    height: 4rem;
    width: 4rem;

    &.small {
      height: 1.125rem;
      width: 1.125rem;
    }
  }

  @keyframes spin {
    to {
      border-top-color: theme-color('secondary');
      transform: rotate(360deg);
    }
  }
</style>
