<template>
  <span
    :aria-checked="pinned"
    :class="{ pinned }"
    class="pinboard-status position-relative"
    @click="toggle"
  >
    <icon
      :class="{ pinned }"
      name="pin"
    />
  </span>
</template>

<script>
import pinnable from '@/mixins/pinnable'

export default {
  name: 'PinboardStatus',
  mixins: [pinnable]
}
</script>

<style lang="scss">
  @import "~@/scss/bootstrap/custom";

  .pinboard-status {
    display: inline-block;
    cursor: pointer;
    color: theme-color('dark');
    transition: max-height .2s ease-in-out, transform .2s .2s ease-in-out, opacity .3s ease-in-out;
    opacity: .38;
    max-height: 24px;
    // transform: translateY(-.125rem);

    @include hover-focus() {
      opacity: .85;
    }

    &.pinned {
      opacity: 1;
      transform: rotate(24deg) translateY(-2px);
      max-height: 18px;
      overflow: hidden;
      transition: max-height .2s .2s ease-in-out, transform .2s ease-in-out, opacity .3s ease-in-out;
    }
  }
</style>
