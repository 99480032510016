<template>
  <Modal
    v-bind="$props"
    centered
    lg
    :dialog-cls="$style.overlay"
    body-cls="p-0"
    v-on="$listeners"
  >
    <iframe
      ref="iframe"
      :src="value ? url : false"
      frameborder="0"
      class="w-100"
      :class="$style.iframe"
    />
  </Modal>
</template>

<script>
import { Modal } from 'vue-bootstrap-modal'

export default {
  name: 'DonateModal',
  components: {
    Modal
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
.iframe {
  height: 100vh;
}

.overlay {
  margin: 0 !important;
  max-width: none !important;
  min-height: 100vh !important;

  :global(.modal-header) {
    position: absolute;
    width: 100%;
  }

  :global(.modal-content) {
    border: 0;
    border-radius: 0;
    height: 100vh !important;
  }

  :global(.close) {
    position: absolute;
    display: block;
    top: 0;
    right: 1.5rem;
    z-index: 100;
    margin-top: 0.275rem;
    color: theme-color('primary');
    opacity: 1;
    @include fill-background-image('@/assets/icons/close.svg?inline', theme-color('primary'));
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: center;

    > span {
      visibility: hidden;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0.75rem !important;
    }
  }
}
</style>
