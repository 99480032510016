import * as feeds from '@/constants/feeds'
import { SET_BLOG_URL } from '@/store/mutation-types'

const DEFAULT_FEED = 'global'

export default {
  state() {
    return {
      url: DEFAULT_FEED
    }
  },
  mutations: {
    [SET_BLOG_URL](state, key) {
      if (key in feeds) {
        state.url = key
      } else {
        state.url = DEFAULT_FEED
      }
    }
  },
  actions: {
    initBlog({ commit }) {
      const { location } = window
      const search = typeof location !== 'undefined' ? location.search : undefined
      let feed = DEFAULT_FEED

      if (search) {
        feed = (new URLSearchParams(search)).get('feed')
        commit(SET_BLOG_URL, feed)
      } else {
        fetch(`${process.env.VUE_APP_NUXT_API_URL}viewer`)
          .then((response) => {
            if (response.ok) {
              response.json().then(({ countryIso2 }) => {
                commit(SET_BLOG_URL, countryIso2.toLowerCase())
              })
            }
          })
          .catch(() => {
            // do nothing
          })
      }
    }
  },
  getters: {
    blogUrl: state => feeds[state.url]
  }
}
