import { SET_PLAYER } from '../mutation-types'

export default {
  namespaced: true,
  state() {
    return {
      player: undefined
    }
  },
  mutations: {
    [SET_PLAYER](state, player) {
      state.player = player
    }
  },
  actions: {
    toggle({ commit, state: { player }, getters: { currentSrc } }, url) {
      if (player) {
        player.pause()
        player.src = '' // eslint-disable-line no-param-reassign
        commit(SET_PLAYER)
      }

      if (url && currentSrc !== url) {
        const audio = new Audio(url)
        audio.play()
          .then(() => {
            commit(SET_PLAYER, audio)
          })
      }
    }
  },
  getters: {
    currentSrc: ({ player }) => (player ? player.src : undefined)
  }
}
