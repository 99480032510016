<template>
  <transition name="slide-in-bottom">
    <aside
      v-if="value"
      :class="$style.overlay"
      @click="$emit('input', false)"
    >
      <nav class="nav flex-column mt-auto w-100 bg-white text-uppercase font-weight-bold font-lg px-2">
        <router-link
          v-for="(title, iso) in $options.languages"
          :key="iso"
          :class="{ active: iso === locale }"
          :to="{
            ...$route,
            params: {
              ...$route.params,
              lang: iso
            }
          }"
          role="button"
          class="nav-link"
        >
          {{ title }}
        </router-link>
      </nav>
    </aside>
  </transition>
</template>

<script>
import languages from '@/constants/languages'

export default {
  name: 'LanguageOverlay',
  languages,
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    }
  },
  watch: {
    locale() {
      this.$emit('input', false)
    }
  }
}
</script>

<style lang="scss" module>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: $zindex-modal + 5;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
</style>
