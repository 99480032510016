<template>
  <!-- eslint-disable vue/no-v-html -->
  <component
    :is="size === 'xl' ? 'OverlayModal' : 'Modal'"
    centered
    :value="!!message && !dismissed"
    :closeable="closeable"
    :sm="size === 'sm'"
    :lg="size === 'lg'"
    @input="dismissed = true"
  >
    <template #title>
      {{ $t('globalAlerts.title') }}
    </template>
    <p
      :class="['mb-0', size === 'xl' && 'p-3']"
      v-html="message"
    />
  </component>
</template>

<script>
import OverlayModal from '@/components/OverlayModal.vue'
import { Modal } from 'vue-bootstrap-modal'
import { WATCH_UPDATE_INTERVAL } from '@/constants/intervals'

export default {
  name: 'GlobalAlerts',
  components: {
    Modal,
    OverlayModal
  },
  data() {
    return {
      globalAlerts: null,
      country: 'ALL',
      message: null,
      closeable: true,
      dismissed: false,
      size: 'md'
    }
  },
  watch: {
    message(message, oldMessage) {
      if (message && message !== oldMessage) {
        this.dismissed = false
      }
    },
    closeable(closeable, closeableBefore) {
      if (closeable !== closeableBefore) {
        this.dismissed = false
      }
    }
  },
  mounted() {
    this.fetchAlerts()
    fetch(`${process.env.VUE_APP_NUXT_API_URL}viewer`)
      .then((response) => {
        if (response.ok) {
          response.json()
            .then(({ countryIso2 }) => {
              this.country = countryIso2
              this.parseMessage()
            })
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('failed to fetch viewer data', e)
      })
    const i = setInterval(() => this.fetchAlerts(), WATCH_UPDATE_INTERVAL)
    this.$on('hook:beforeDestroy', () => {
      clearInterval(i)
    })
  },
  methods: {
    // eslint-disable-next-line consistent-return
    async fetchAlerts() {
      try {
        const response = await fetch(
          `${process.env.BASE_URL}${process.env.VUE_APP_JSON_URL}globalAlerts.json`
        )
        this.globalAlerts = await response.json()
        this.parseMessage()
      } catch (e) {
        // ignore error -> file probably doesn't exist
      }
    },
    parseMessage() {
      if (!this.globalAlerts) {
        return
      }
      try {
        const countryMessages = this.globalAlerts[this.country] || this.globalAlerts.ALL
        if (countryMessages) {
          this.closeable = !countryMessages.blocking
          this.size = countryMessages.size || 'md'
          this.message = countryMessages[this.$i18n.locale] || countryMessages.default
        } else {
          this.message = null
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Failed to parse global alerts: ', e)
      }
    }
  }
}
</script>
