export const MINIMIZED = 'MINIMIZED'
export const MAXIMIZED = 'MAXIMIZED'
export const DOCKED = 'DOCKED'

export const positions = {
  MINIMIZED,
  MAXIMIZED,
  DOCKED
}

export const fullScreenRoutes = [
  'participants.detail',
  'teams.detail',
  'locations.detail'
]
