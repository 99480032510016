import Vue from 'vue'
import Router from 'vue-router'
import languages from '@/constants/languages'
import routes from './routes'

Vue.use(Router)

// eslint-disable-next-line import/prefer-default-export
export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes,
  scrollBehavior() {
    const sidebar = document.getElementById('sidebar')
    if (sidebar) {
      sidebar.scrollTop = 0
    }
    return { top: 0 }
  }
})

const languageKeys = Object.keys(languages)
router.beforeEach((to, from, next) => {
  const hasLocale = languageKeys.includes(to.params.lang)
  if (!hasLocale) {
    next({
      name: to.name,
      params: { lang: 'en' }
    })
  } else {
    next()
  }
})
