var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"slide-in-bottom"}},[(_vm.value)?_c('aside',{class:_vm.$style.overlay,on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('nav',{staticClass:"nav flex-column mt-auto w-100 bg-white text-uppercase font-weight-bold font-lg px-2"},_vm._l((_vm.$options.languages),function(title,iso){return _c('router-link',{key:iso,staticClass:"nav-link",class:{ active: iso === _vm.locale },attrs:{"to":{
          ..._vm.$route,
          params: {
            ..._vm.$route.params,
            lang: iso
          }
        },"role":"button"}},[_vm._v(" "+_vm._s(title)+" ")])}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }