export default {
  cs: 'Čeština',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  hr: 'Hrvatski',
  hu: 'Magyar nyelv',
  it: 'Italiano',
  ja: '日本語',
  ka: 'ქართული',
  nl: 'Nederlands',
  pl: 'Polski',
  'pt-br': 'Português do Brasil',
  ru: 'Pусский',
  sk: 'Slovenčina',
  sl: 'Slovenščina',
  tr: 'Türkçe',
  uk: 'Украї́нська',
  zh: '正體中文'
}
