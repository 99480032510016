import TWEEN from '@tweenjs/tween.js'

export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      tweeningValue: (0).toFixed(this.decimals)
    }
  },
  watch: {
    value(newValue, oldValue) {
      this.tween(oldValue, newValue)
    }
  },
  mounted() {
    this.reset()
  },
  methods: {
    tween(startValue, endValue) {
      const vm = this

      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate)
        }
      }

      new TWEEN.Tween({ tweeningValue: startValue })
        .to({ tweeningValue: endValue }, 500)
        .onUpdate((object) => {
          vm.tweeningValue = object.tweeningValue.toFixed(vm.decimals)
        })
        .start()

      animate()
    },
    reset() {
      this.tween(0, Number(this.value))
    }
  }
}
