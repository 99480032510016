import _mergeJSXProps from "babel-helper-vue-jsx-merge-props";
export default {
  name: 'RunnerStart',
  functional: true,
  render: function (h, ctx) {
    return h(
      "svg",
      _mergeJSXProps([{
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }
      }, ctx.data]),
      [h("path", {
        attrs: { d: "M15.67 13.6c-.04.09-.73.97-.76.73-.01-.08.02-.15.09-.19.33-.2.45-.94.28-.94-.06 0-.17.57-.36.49-.18-.06.43-1.31.03-2.56-.42-1.37-.45-2.77-.74-4.16-.46.87-.17 1.7-.18 2.58 0 .11-.02.22-.01.34.01.14.43 1.8.44 2.03 0 3.52-.49 3.37-.55 4.92-.11 2.49.44 1.24-.04 3.86-.19 1.06-.74 2.18.31 2.92.13.08.26.13.22.23-.02.07-.08.1-.15.09-.49-.04-1.52.29-1.59-.2-.02-.15-.05-.3-.01-.46.01-.04 0-.07-.01-.1a.61.61 0 0 1 .02-.46c.25-.57-.1-1.5-.15-3.04-.02-.46.21-.8.06-1.26-.31-.91-.05-.74-.52-4.95 0-.02 0-.04-.01-.06 0-.05-.03-.09-.07-.11-.04.02-.07.05-.08.1 0 .32-.39 2.63-.41 4.39-.01.52-.17.91-.11 1.44.13 1.23-.38 2.89-.11 3.51.11.23-.03.35.01.56.05.26.04.64-.28.7-.05 0-1.02-.04-1.18-.06-.11.03-.2 0-.28-.1-.02-.07.04-.11.08-.13.26-.13.45-.34.64-.55.47-.52-.01-1.61-.13-2.21-.51-2.69.01-2.43-.04-3.05-.12-2.3-.5-1.54-.59-5.88 0-.06 0-.11.01-.16.01-.18.06-.35.11-.52.14-.42.18-.88.31-1.3.06-.19-.02-.45.09-.91.15-.67-.04-1.73-.28-2.12-.04.37-.51 3.51-.86 4.63-.17.67.15.46.15 1.14 0 .42.07.8.07.81 0 .03.02.08-.03.09a.1.1 0 0 1-.11-.04c-.25-.29-.16-.55-.26-.52-.07 0-.08.28-.07.35.04.18.12.35.2.52.05.13.22.11.24.3.01.07-.03.1-.1.07-.97-.41-.75-1.91-.78-2.43-.01-.1 0-.19.01-.28.09-1.06-.11-2.08.3-3.1.32-.78-.41-3.92 1-4.47.02-.01.05-.02.07-.02.06-.02.12-.02.18-.04.43-.12 1.66-.55 1.56-1.14-.03-.21-.12-.34-.19-.62 0-.01 0-.01-.01-.01-.16.04-.25-.61-.18-.68.01-.02.03-.03.05-.02.1.05-.22-.72.12-1.01.2-.14.07-.3.43-.42.2-.3 1.37-.05 1.37.3 0 .02.01.03.03.04.37.11.03 1.13.13 1.08.15-.05.01.78-.15.71-.01 0-.01 0-.01.01-.02.08-.04.16-.07.24-.25.93.53 1.16 1.28 1.47.16.07.3.07.38.1.77.24.97 1.68.82 2.06-.1.31.07.72.09 1.04.03.52-.07 1.03.11 1.53.59 1.63.14 3.28.25 4.34.07.2 0 .38-.08.56z" }
      })]
    );
  }
};