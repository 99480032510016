<template>
  <ul
    :class="[$style.tabs, dockedPlayer && $style.docked, 'nav nav-tabs nav-fill']"
    role="tablist"
  >
    <router-link
      v-for="{ name, nameChoice } in excludeShopFromMenuItems(menu)"
      :key="name"
      v-slot="{ href, navigate, isActive }"
      class="nav-item"
      :to="{ name, params: { lang: $i18n.locale } }"
      @click.native="trackClick(nameChoice >= 0 ? $tc(`menu.${name}`, nameChoice) : $t(`menu.${name}`))"
    >
      <li :class="['nav-item']">
        <a
          :class="['nav-link', isActive && 'active']"
          :href="href"
          @click="navigate"
        >
          <template v-if="nameChoice >= 0">{{ $tc(`menu.${name}`, nameChoice) }}</template>
          <template v-else>{{ $t(`menu.${name}`) }}</template>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import { DOCKED } from '@/constants/liveStream'
import menuItems from '@/mixins/menuItems'

export default {
  name: 'NavTabs',
  mixins: [menuItems],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    dockedPlayer() {
      return this.$store.state.liveStream.position === DOCKED
    }
  },
  methods: {
    trackClick(text) {
      this.$gtmEvent({
        event: 'gaEvent',
        eventCategory: 'Navigation',
        eventAction: 'Mobile Tabs Navigation',
        eventLabel: text
      })
      this.$gtmEvent({
        event: 'ga4Event',
        event_name: 'navigation',
        navigation: {
          element_position: 'Mobile Tabs Navigation',
          content_name: text
        }
      })
    },
    excludeShopFromMenuItems(menu) {
      return menu.filter(menuItem => menuItem.name !== 'shop')
    }
  }
}
</script>

<style lang="scss" module>
.tabs {
  position: sticky;
  top: $navbar-height-sm;
  left: 0;
  right: 0;
  width: 100%;
  height: $nav-tabs-height;
  background-color: $white;
  box-shadow: $navbar-shadow;
  z-index: $zindex-fixed - 2;

  &.docked {
    position: absolute;
    top: add($navbar-height-sm, 100vw * divide(9, 16));

    ~ :global(.live-stream-docked) {
      // increase padding for .site-content to accomodate for nav-tabs height
      padding-top: $navbar-height-sm + $nav-tabs-height;
    }
  }

  ~ :global(.site-content) {
    //padding-top: $navbar-height-sm + $nav-tabs-height;
  }

  @include media-breakpoint-up($split-view-breakpoint) {
    display: none !important;
  }
}
</style>
