<template functional>
  <section :class="[data.staticClass, data.class]">
    <slot name="header"/>
    <div
      v-if="slots().default"
      class="container my-3"
    >
      <slot/>
    </div>
    <slot name="full"/>
  </section>
</template>

<script>
export default {
  name: 'AsideContainer'
}
</script>

<style lang="scss">

</style>
