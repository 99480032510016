/* eslint-disable no-plusplus */
let counter = 1

export default class UnknownRunner {
  constructor({ id = `unknownRunner${counter++}`, fullname = 'Unknown Runner', ...data }) {
    this.id = id
    this.fullname = fullname
    Object.assign(this, data)
  }
}
