<template>
  <div
    :class="{ 'flex-column justify-content-center': column }"
    class="share-widget d-flex"
  >
    <div
      :data-href="url"
      :class="column ? 'mb-1' : 'mr-2'"
      class="fb-share-button d-flex"
      data-layout="button"
      data-size="small"
      data-mobile-iframe="true"
    >
      <a
        :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&src=sdkpreparse`"
        target="_blank"
        class="fb-xfbml-parse-ignore"
      >
        Teilen
      </a>
    </div>
    <a
      class="twitter-share-button"
      href="https://twitter.com/intent/tweet"
    >
      Tweet
    </a>
  </div>
</template>

<script>

  /* global twttr, FB */
export default {
  name: 'ShareWidget',
  props: {
    column: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    url() {
      const { location } = window

      if (typeof location !== 'undefined') {
        return location.href
      }
      return ''
    }
  },
  mounted() {
    this.init()
  },
  activated() {
    this.init()
  },
  methods: {
    init() {
      if (typeof twttr !== 'undefined' && typeof twttr.widgets !== 'undefined') {
        twttr.widgets.load()
      }
      if (typeof FB !== 'undefined') {
        FB.XFBML.parse(document.getElementById('shareButton'))
      }
    }
  }
}
</script>

<style lang="scss">
  .fb-share-button, .twitter-share-button {
    width: 61px;
    height: 20px;
  }
</style>
