<template functional>
  <div
    :class="[data.staticClass, data.class]"
    class="card hero-card border-0"
  >
    <div :class="$style.image">
      <img
        v-if="props.image"
        v-bind="props.image"
        class="card-img"
      >
    </div>
    <div class="card-img-overlay d-flex flex-column justify-content-end align-items-center">
      <div class="card-img-overlay-top d-flex align-items-center justify-content-between">
        <button
          class="btn btn-outline-light btn-sm mr-3"
          @click.prevent="parent.$router.go(-1)"
        >
          <icon name="ArrowLeft"/>
          {{ parent.$t('back') }}
        </button>

        <pinboard-status
          v-if="props.pinnable"
          v-bind="props.pinnable"
          class="ml-2 text-white"
        />
      </div>
      <div class="card-img-overlay-content mb-3">
        <slot/>
      </div>
      <slot name="overlay"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroCard'
}
</script>

<style lang="scss">
  .hero-card {
    max-height: 50vh;

    .card-img-overlay {
      background-color: rgba(theme-color('primary'), .6);

      &-content {
        display: inherit;
        flex-direction: inherit;
        align-items: inherit;
        justify-content: inherit;
        position: relative;
        z-index: 1;
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 7rem;
        background: linear-gradient(to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(10, 10, 10, 0.3) 40%,
          rgba(0, 0, 0, 0.7) 100%
        );
        z-index: 0;
      }
    }

    .card-img-overlay-top {
      position: absolute;
      top: $card-img-overlay-padding;
      left: $card-img-overlay-padding;
      right: $card-img-overlay-padding;
      z-index: 3;
    }
  }
</style>

<style lang="scss" module>
  .image {
    position: relative;
    padding-top: divide(13, 16) * 100%;

    > :global(.card-img) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
  }
</style>
