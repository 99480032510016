<template>
  <Modal
    v-bind="$props"
    centered
    lg
    :dialog-cls="$style.overlay"
    header-cls="bg-white shadow"
    :body-cls="['p-0', $props.bodyCls]"
    v-on="$listeners"
  >
    <template #title>
      <a
        class="navbar-brand"
        href="/live"
      >
        <img
          :alt="$title"
          :title="$title"
          src="@/assets/logo.png"
          width="102"
          height="48"
        >
      </a>
      <slot name="title"/>
    </template>

    <template #default="{ close }">
      <slot :close="close"/>
    </template>

    <template #footer="{ close }">
      <slot
        :close="close"
        name="footer"
      />
    </template>
  </Modal>
</template>

<script>
import { Modal } from 'vue-bootstrap-modal'

export default {
  name: 'OverlayModal',
  components: {
    Modal
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    bodyCls: {
      type: [Object, Array, String],
      default: undefined
    },
    footerCls: {
      type: [Object, Array, String],
      default: undefined
    }
  }
}
</script>

<style lang="scss" module>
.overlay {
  margin: 0 !important;
  max-width: none !important;
  min-height: 100vh !important;

  :global(.modal-header) {
    padding: .75rem 1rem;

    @include media-breakpoint-up(lg) {
      padding: $modal-header-padding;
    }
  }

  :global(.modal-content) {
    border: 0;
    border-radius: 0;
    min-height: 100vh !important;
  }

  :global(.close) {
    margin-top: -0.5rem;
    padding-right: 1.25rem;
    color: theme-color('primary');
    opacity: 1;
    @include fill-background-image('@/assets/icons/close.svg?inline', theme-color('primary'));
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: center;

    > span {
      visibility: hidden;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0 !important;
    }
  }
}
</style>
