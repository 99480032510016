import i18n from '@/i18n'

/**
 * checks if forward slash within link, if not appends
 * @param link {string} any link, url
 * @returns {string} link with or without slash
 */
export function appendForwardSlash(link) {
  return link.charAt(0) === '/' ? link : `/${link}`
}

/**
 * get the current origin + locale
 * @param link {string} any link, url
 * @return string full current page origin link with locale + given link
 */
export function getLocaleLink(link) {
  const normalizedLink = appendForwardSlash(link)
  return `${window.origin}/${i18n.locale}${normalizedLink}`
}
