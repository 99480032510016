import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './messages/en.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en', // @todo: get browser locale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: { en }
})

const loadedLanguages = ['en'] // our default language that is prelaoded

function setI18nLanguage(lang) {
  i18n.locale = lang
  if (typeof document !== 'undefined') {
    document.querySelector('html').setAttribute('lang', lang)
  }
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/messages/${lang}.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}

export default i18n
