<template>
  <div class="position-relative">
    <input
      :value="value"
      type="search"
      v-bind="{ placeholder, name, ...$attrs }"
      :class="['form-control', $style.noClear, inputClass]"
      v-on="{
        ...$listeners,
        input: ($event) => $emit('input', $event.target.value)
      }"
    >
    <button
      v-if="showClear || value"
      :class="['close', $style.clearBtn]"
      type="button"
      @click="$listeners.clear
        ? $listeners.clear()
        : ($listeners.change ? $listeners.change({ target: { value: '' }}) : $listeners.input(''))"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: undefined
    },
    showClear: {
      type: Boolean,
      default: true
    },
    inputClass: {
      type: [Object, Array, String],
      default: undefined
    }
  }
}
</script>

<style lang="scss" module>
.input {
  :global(.btn) {
    height: 2.5rem;

    // reset glow within input-group
    outline: none !important;
    box-shadow: none !important;
  }

  :global(.btn) {
    // reset glow within input-group
    outline: none !important;
    box-shadow: none !important;
  }
}

.clearBtn {
  position: absolute;
  top: calc(50% - .125rem);
  padding: map-get($spacers, 1);
  right: $input-padding-x;
  transform: translateY(-50%);
  color: currentColor;
  box-shadow: none !important;
  border-color: transparent !important;
  z-index: 5;
  display: flex;
  align-items: center;

  //> span {
  //  display: inline-block;
  //}

  &:global(.close) {
    margin-top: 0;
  }

  @include hover-focus() {
    color: currentColor;
  }
}

.noClear {
  /* clears the 'X' from Internet Explorer */
  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}
</style>
