/* eslint-disable camelcase */
import avatar from '@/assets/avatar/location.gif'

export default function transformLocation({
  id,
  webcode,
  title,
  keywords,
  lat,
  lng,
  iso2,
  country: ioc,
  status,
  runners,
  leading_man,
  leading_woman,
  // eslint-disable-next-line no-unused-vars
  href,
  link,
  image = avatar,
  type,
  timeOfDay,
  timeOffset = 0,
  characteristics,
  weather: w
}) {
  const leadingManId = leading_man ? leading_man.id : null
  const leadingWomanId = leading_woman ? leading_woman.id : null

  const weather = w && !(w instanceof Array) ? {
    temp: w.TEMP,
    tempL: w.TEMP_L,
    tempH: w.TEMP_H,
    humidity: w.HUM_REL,
    wind: w.WIND,
    icon: w.SYM_W50,
    uv: w.RAD_UV_I,
    precipiation: w.PROB_PREC,
    pressure: w.PRES_MSL
  } : undefined

  return {
    id,
    webcode,
    title,
    keywords: keywords.toLowerCase(),
    lat,
    lng,
    iso2: webcode === 'app' ? 'app' : iso2,
    ioc,
    status,
    runners,
    leadingManId,
    leadingWomanId,
    url: { name: 'locations.detail', params: { id: webcode } },
    link: link || `/locations/${webcode}`,
    image,
    type,
    timeOfDay,
    timeOffset,
    characteristics,
    weather
  }
}
