export const status = {
  RUNNING: 'running',
  FINISHED: 'finished',
  DISQUALIFIED: 'dq',
  PRE_RACE: 'prerace',
  REGISTERED: 'registered',
  CANCELLED: 'cancelled',
  CAUGHT: 'caught',
  OFFLINE: 'offline',

  // deprecated
  NOT_CATCHED: 'dnc',
  NOT_STARTED: 'dns',
  UNKNOWN: 'unk'
}

export const colors = {
  m: 'text-male',
  f: 'text-female',
  undefined: 'text-secondary'
}

export const athleteTypes = {
  APP: 'app',
  RUNNER: 'runner',
  WHEELCHAIR: 'wheelchair'
}

export function icon({ gender: g, status: st, athleteType = athleteTypes.RUNNER }) {
  const isFemale = g === 'f' && athleteType === athleteTypes.RUNNER
  const gender = athleteType === athleteTypes.WHEELCHAIR ? '' : `${isFemale ? 'fe' : ''}male`
  const type = athleteType === athleteTypes.WHEELCHAIR ? 'wheelchair' : 'runner'

  const icons = {
    [status.RUNNING]: `${type}${gender ? `-${gender}` : ''}`,
    [status.FINISHED]: `${type}-finished`,
    [status.PRE_RACE]: `${type}-start`,
    [status.REGISTERED]: `${type}-start`,
    [status.CANCELLED]: `${type}-finished`,
    [status.CAUGHT]: `${type}-finished`,
    [status.OFFLINE]: `${type}-${gender ? `${gender}-` : ''}error`,

    // deprecated
    [status.DISQUALIFIED]: `${type}-start`,
    [status.NOT_CATCHED]: `${type}${gender ? `-${gender}` : ''}`,
    [status.NOT_STARTED]: `${type}-start`,
    [status.UNKNOWN]: `${type}-${gender ? `${gender}-` : ''}error`
  }

  return st in icons ? icons[st] : 'runner-male'
}
