<template>
  <media-object v-bind="itemProps">
    <template slot="after">
      <StatusBadge
        v-if="[$options.modes.LIVE, $options.modes.POST].includes(mode)"
        :value="value.status || $options.status.RUNNING"
      />
    </template>
  </media-object>
</template>

<script>
import StatusBadge from '@/components/StatusBadge.vue'
import { status } from '@/constants/participant'
import mediaObjectMixin from '@/mixins/mediaObject'
import * as modes from '@/constants/modes'
import { mapGetters } from 'vuex'

export default {
  name: 'TeamListItem',
  status,
  modes,
  components: {
    StatusBadge
  },
  mixins: [mediaObjectMixin],
  computed: {
    ...mapGetters([
      'mode'
    ]),
    icon() {
      const { gender } = this.value
      if (gender === 'f') {
        return 'runner-female'
      }

      return 'runner-male'
    },
    itemProps() {
      const team = this.value

      return {
        key: team.id,
        title: team.name,
        text: this.$tc('team.count', team.count, [team.count]),
        image: team.avatar,
        url: team.url
      }
    }
  }
}
</script>

<style lang="scss">

</style>
