<template>
  <div
    :class="[
      'card border mb-2 shadow-none',
      checked && 'border-primary',
      $style.radioSelect,
    ]"
  >
    <div class="custom-control custom-radio">
      <input
        :id="$id"
        v-model="$val"
        v-bind="boundProps"
        :value="checked"
        type="radio"
        class="custom-control-input"
      >
      <label
        :for="$id"
        :class="['custom-control-label', $style.wrap]"
      >
        <slot>
          <span
            v-if="label"
            class="radio-label"
          >{{ label }}</span>
        </slot>
      </label>
    </div>
    <div
      v-if="$scopedSlots.body && $val === value"
      class="card-body"
    >
      <slot name="body"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioSelect',
  inheritAttrs: false,
  model: {
    prop: 'checked'
  },
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Object, Array],
      default: undefined
    },
    label: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      default: 'radio'
    },
    checked: {
      type: [Boolean, Object, Array, String, Number],
      default: false
    }
  },
  computed: {
    $val: {
      get() {
        const { model: { prop = 'value' } = {} } = this.$options
        return this[prop]
      },
      set(value) {
        this.$emit('input', value === this.value && this.value)
      }
    },
    boundProps() {
      // value must be assigned :value="value" otherwise v-model doesn't send value correctly!
      const {
        label, value, showErrors, ...props
      } = this.$props
      return {
        ...props,
        ...this.$attrs
      }
    },
    $id() {
      return this.name + (this.$vnode.key || this.value || '')
    }
  }
}
</script>

<style lang="scss" module>
  .wrap {
    padding: 0.6125rem 1.5rem !important;
    font-weight: 700;
    color: $gray-600;
  }

  .radioSelect {
    &:global(.border) {
      border-width: .125rem !important;
    }

    :global(.custom-control-label) {
      &:before, &:after {
        top: .75rem;
        left: -.5rem;
      }
    }

    :global(.custom-control-input) {
      &:checked + :global(.custom-control-label) {
        color: theme-color('primary');
      }
    }
  }

</style>
