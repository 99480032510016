var render = function render(){var _vm=this,_c=_vm._self._c;return _c('OverlayModal',_vm._g(_vm._b({attrs:{"dialog-cls":"bg-body","body-cls":"d-flex","footer-cls":"bg-white"},scopedSlots:_vm._u([{key:"footer",fn:function({ close }){return [(_vm.success)?_c('div',{key:"close",staticClass:"w-100 text-center"},[_c('button',{directives:[{name:"t",rawName:"v-t",value:('close'),expression:"'close'"}],staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":close}})]):_c('div',{key:"submit",staticClass:"w-100 text-center"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"form":"audioStickerForm","type":"submit","disabled":_vm.loading && !_vm.enabled}},[_vm._v(" "+_vm._s(_vm.$t('audioStickerOverlay.chooseSticker.submit'))+" ")])])]}}])},'OverlayModal',_vm.$props,false),_vm.$listeners),[(_vm.success)?_c('div',{staticClass:"container py-3 py-sm-4 py-md-5 py-xl-6 text-center align-self-center"},[_c('Avatar',{staticClass:"mb-3",attrs:{"runner":_vm.participant,"lg":""}}),_c('span',{directives:[{name:"t",rawName:"v-t",value:('audioStickerOverlay.success.badge'),expression:"'audioStickerOverlay.success.badge'"}],staticClass:"badge badge-outlined mb-3"}),_c('h2',{staticClass:"fs-32"},[_vm._v(_vm._s(_vm.participant.fullname))]),_c('p',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'audioStickerOverlay.success.message',
        args: [_vm.participant ? _vm.participant.firstname : '']
      }),expression:"{\n        path: 'audioStickerOverlay.success.message',\n        args: [participant ? participant.firstname : '']\n      }"}],staticClass:"font-lg"})],1):_c('form',{staticClass:"container py-3 py-sm-4 py-md-5 py-xl-6 text-center align-self-center",attrs:{"id":"audioStickerForm","name":"audioSticker"},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('audioStickerOverlay.chooseSticker.header'),expression:"'audioStickerOverlay.chooseSticker.header'"}],staticClass:"fs-32"}),_c('p',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'audioStickerOverlay.chooseSticker.text',
        args: [_vm.participant ? _vm.participant.firstname : '']
      }),expression:"{\n        path: 'audioStickerOverlay.chooseSticker.text',\n        args: [participant ? participant.firstname : '']\n      }"}],staticClass:"font-lg"}),_c('div',{staticClass:"row justify-content-center my-5"},_vm._l((_vm.stickers),function({ name, src }){return _c('AudioStickerRadioButton',{key:name,attrs:{"value":name,"src":src,"name":"audioSticker"},model:{value:(_vm.sticker),callback:function ($$v) {_vm.sticker=$$v},expression:"sticker"}})}),1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }