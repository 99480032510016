<template>
  <div>
    <span
      v-t="`${type}.status.${value}`"
      :class="[
        'badge text-uppercase text-condensed smaller py-2 px-2.5',
        [$options.status.FINISHED, $options.status.DISQUALIFIED, $options.status.CANCELLED].includes(value)
          ? 'badge-gray'
          : 'badge-secondary'
      ]"
    />
  </div>
</template>

<script>
import { status } from '@/constants/participant'

export default {
  name: 'StatusBadge',
  status,
  props: {
    value: {
      type: String,
      validator: value => Object.values(status)
        .includes(value),
      required: true
    },
    type: {
      type: String,
      default: 'participant',
      validator: type => ['participant', 'location'].includes(type)
    }
  }
}
</script>
