<template>
  <div class="units-indicator d-flex text-center">
    <template
      v-for="unit in $options.units"
    >
      <RadioSelect
        v-if="checkbox"
        :key="unit"
        :label="$options.labels[unit]"
        :value="unit"
        :checked="unit === units"
        class="mr-2"
        name="units"
        @input="setUnits(unit)"
      />
      <button
        v-else
        :key="unit"
        :class="[themedClass('btn-outline'), { 'selected': units === unit, 'btn-sm': sm }]"
        type="button"
        class="btn text-uppercase px-1 border-0 w-50 d-block"
        @click="setUnits(unit)"
      >
        {{ $options.labels[unit] }}
      </button>
    </template>
  </div>
</template>

<script>
import RadioSelect from '@/components/form/RadioSelect.vue'
import { mapGetters, mapActions } from 'vuex'
import * as units from '@/constants/units'
import themeable from '@/mixins/themeable'

export default {
  name: 'DistanceUnit',
  components: { RadioSelect },
  units,
  mixins: [themeable],
  labels: {
    [units.METRIC]: 'km',
    [units.IMPERIAL]: 'mi'
  },
  props: {
    sm: {
      type: Boolean,
      default: false
    },
    checkbox: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'units'
    ])
  },
  methods: {
    ...mapActions([
      'setUnits'
    ])
  }
}
</script>

<style lang="scss">
  .units-indicator {
    .btn {
      font-size: $font-size-lg;

      &:not(:hover) {
        opacity: .3;
      }

      &.selected {
        font-weight: bold;
        opacity: 1;
      }
    }
  }
</style>
