<template>
  <router-link :to="{ ...$route, hash: '#language' }">
    <icon src="@/assets/icons/language.svg"/>
    <span class="ml-2">
      {{ $options.languages[$i18n.locale] }}
    </span>
  </router-link>
</template>

<script>
import languages from '@/constants/languages'

export default {
  name: 'LanguageButton',
  languages
}
</script>

<style lang="scss">

</style>
