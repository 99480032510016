import { PIN, UNPIN } from '../mutation-types'

function reduce({ type, id }) {
  return `${type}:${id}`
}

function accumulate(item, maps) {
  const [type, id] = item.split(':')
  const getter = maps[type]
  if (!getter) {
    return undefined
  }

  const accumulatedItem = typeof getter === 'function' ? getter(id) : getter[id]

  if (accumulatedItem) {
    return {
      type,
      item: accumulatedItem
    }
  }

  return undefined
}

export default {
  state() {
    return []
  },
  mutations: {
    [PIN](state, payload) {
      const item = reduce(payload)
      if (!state.includes(item)) {
        state.push(item)
      }
    },
    [UNPIN](state, payload) {
      const index = state.indexOf(reduce(payload))
      if (index >= 0) {
        state.splice(index, 1)
      }
    }
  },
  actions: {
    pin({ commit }, payload) {
      commit(PIN, payload)
    },
    unPin({ commit }, payload) {
      commit(UNPIN, payload)
    },
    cleanupPinboard({ state, commit }, { type: validType, validIds }) {
      const invalid = state
        .map(item => item.split(':'))
        .filter(([type, id]) => type === validType && validIds.indexOf(id) < 0)

      if (invalid.length) {
        invalid.forEach(([type, id]) => {
          commit(UNPIN, { id, type })
        })
      }
    }
  },
  getters: {
    // @todo handle state, where participants have not been loaded yet
    pinned: (state, getters) => state.map(item => accumulate(item, {
      participant: getters.participantById,
      location: getters.locationById,
      team: getters.teamById
    })).filter(item => !!item),
    pinnedCount: state => state.length,
    isPinned: state => (type, id) => state.includes(reduce({ type, id }))
  }
}
