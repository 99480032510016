// app
export const SET_REFRESH = 'SET_REFRESH'
export const SET_MODE = 'SET_MODE'
export const SET_LOCALE = 'SET_LOCALE'
export const SET_TICKRATE = 'SET_TICKRATE'
export const SET_UNITS = 'SET_UNITS'
export const SHOW_COUNTERS = 'SHOW_COUNTERS'
export const SET_FEATURES = 'SET_FEATURES'
export const SET_RACE_FINISHED = 'SET_RACE_FINISHED'

// event
export const SET_EVENT_DATE = 'SET_EVENT_DATE'
export const SET_CATCHER_CAR_SPEED = 'SET_CATCHER_CAR_SPEED'
export const SET_CATCHER_CAR_DISTANCE = 'SET_CATCHER_CAR_DISTANCE'
export const SET_LEADERS = 'SET_LEADERS'
export const SET_IN = 'SET_IN'
export const SET_OUT = 'SET_OUT'

// radio
export const SET_PLAYER = 'SET_PLAYER'

// locations
export const SET_LOCATION = 'SET_LOCATION'
export const PATCH_LOCATION = 'PATCH_LOCATION'

// participants
export const SET_PARTICIPANT = 'SET_PARTICIPANT'
export const PATCH_PARTICIPANT = 'PATCH_PARTICIPANT'

// team
export const SET_TEAM = 'SET_TEAM'
export const PATCH_TEAM = 'PATCH_TEAM'

// pinboard
export const PIN = 'PIN'
export const UNPIN = 'UNPIN'

// points
export const SET_POINTS = 'SET_POINTS'

// liveStream
export const SET_POSITION = 'SET_POSITION'

// donateModal
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN'
export const SET_MODAL_URL = 'SET_MODAL_URL'

// blog feeds
export const SET_BLOG_URL = 'SET_BLOG_URL'
