var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `media media-object p-3 my-${_vm.margin}`,
    _vm.$style.media,
    _vm.imageShape === 'square' && _vm.$style.imgSquare
  ]},[_c('router-link',{staticClass:"position-relative",attrs:{"to":_vm.url}},[_vm._t("beforeImg"),_c('img',{class:[
        'align-self-center mr-3',
        _vm.imageShape === 'square' ? 'rounded-left' : 'rounded-circle'
      ],attrs:{"src":_vm.imgSrc,"alt":_vm.title},on:{"error":_vm.replaceWithDefaultImage}})],2),_c('router-link',{staticClass:"media-body align-self-center",attrs:{"to":_vm.url}},[_c('h5',{staticClass:"mt-0 mb-0 fs-15 text-uppercase",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._t("default",function(){return [_c('small',{staticClass:"text-condensed text-uppercase font-weight-bold",domProps:{"textContent":_vm._s(_vm.text)}})]})],2),_vm._t("after"),(_vm.pinnable)?_c('pinboard-status',_vm._b({staticClass:"ml-1"},'pinboard-status',_vm.pinnable,false)):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }