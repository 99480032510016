<template>
  <OverlayModal
    v-bind="$props"
    dialog-cls="bg-body"
    body-cls="d-flex"
    footer-cls="bg-white"
    v-on="$listeners"
  >
    <div
      v-if="success"
      class="container py-3 py-sm-4 py-md-5 py-xl-6 text-center align-self-center"
    >
      <Avatar
        :runner="participant"
        class="mb-3"
        lg
      />

      <span
        v-t="'audioStickerOverlay.success.badge'"
        class="badge badge-outlined mb-3"
      />
      <h2 class="fs-32">{{ participant.fullname }}</h2>
      <p
        v-t="{
          path: 'audioStickerOverlay.success.message',
          args: [participant ? participant.firstname : '']
        }"
        class="font-lg"
      />
    </div>
    <form
      v-else
      id="audioStickerForm"
      name="audioSticker"
      class="container py-3 py-sm-4 py-md-5 py-xl-6 text-center align-self-center"
      @submit.prevent="submit"
    >
      <h2
        v-t="'audioStickerOverlay.chooseSticker.header'"
        class="fs-32"
      />
      <p
        v-t="{
          path: 'audioStickerOverlay.chooseSticker.text',
          args: [participant ? participant.firstname : '']
        }"
        class="font-lg"
      />

      <div class="row justify-content-center my-5">
        <AudioStickerRadioButton
          v-for="{ name, src } in stickers"
          :key="name"
          v-model="sticker"
          :value="name"
          :src="src"
          name="audioSticker"
        />
      </div>
    </form>

    <template #footer="{ close }">
      <div
        v-if="success"
        key="close"
        class="w-100 text-center"
      >
        <button
          v-t="'close'"
          type="button"
          class="btn btn-secondary"
          @click="close"
        />
      </div>
      <div
        v-else
        key="submit"
        class="w-100 text-center"
      >
        <button
          form="audioStickerForm"
          class="btn btn-secondary"
          type="submit"
          :disabled="loading && !enabled"
        >
          {{ $t('audioStickerOverlay.chooseSticker.submit') }}
        </button>
      </div>
    </template>
  </OverlayModal>
</template>

<script>
import AudioStickerRadioButton from '@/components/AudioStickerRadioButton.vue'
import Avatar from '@/components/Avatar.vue'
import OverlayModal from '@/components/OverlayModal.vue'
import { mapActions, mapGetters } from 'vuex'
import { getStickers, postSticker } from '@/api'

export default {
  name: 'AudioStickerOverlay',
  components: {
    AudioStickerRadioButton,
    Avatar,
    OverlayModal
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      success: false,
      stickers: [],
      sticker: null
    }
  },
  computed: {
    ...mapGetters([
      'participantById'
    ]),
    enabled() {
      return this.participant && this.stickers.length > 0
    },
    participantId() {
      const { participantId } = this.$route.query
      return participantId || this.$route.params.id
    },
    participant() {
      const participant = this.participantById(this.participantId)
      if (!participant || (participant && participant.incomplete)) {
        this.getParticipantById(this.participantId)
      }

      if (!participant) {
        return undefined
      }

      return participant
    }
  },
  async created() {
    this.stickers = await getStickers()
    this.sticker = this.stickers[0].name
    this.loading = false
  },
  methods: {
    ...mapActions([
      'getParticipantById'
    ]),
    async submit() {
      this.loading = true
      await postSticker(this.sticker, this.participant.userId)
      this.success = true
      this.loading = false
    }
  }
}
</script>

<style lang="scss" module>
.overlay {
  position: absolute !important; // make important to fix hide animation
  top: 0;
  left: 0;
  right: 0;
  //bottom: 0;
  height: 100vh;
  background-color: $body-bg;
  z-index: $zindex-fixed + 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  //@include media-breakpoint-up($split-view-breakpoint) {
  //  top: $navbar-height;
  //  left: $side-navbar-width;
  //  width: subtract(100vw, $side-navbar-width);
  //  min-height: subtract(100vh, $navbar-height);
  //}
}
</style>
